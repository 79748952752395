import consumer from "./consumer"

consumer.subscriptions.create("SignChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        // Called when there's incoming data on the websocket for this channel
        var folder_token = data['folder_token'];
        var watana_firmador_instalado = data['watana_firmador_instalado'];
        document.cookie = "watana_firmador_instalado"+ "=" + btoa(watana_firmador_instalado) + ";" + "expires="+ new Date(2080,11,11).toGMTString()+";path=/";

        var document_token = data['document_token'];
        if ($("#sign_list_" + folder_token).attr("working")) {
            console.log("Iniciando firma multiple folder_token=" + folder_token + " a document=" + document_token);
            $('.sign_action_' + folder_token).addClass('hide');
            $('.sign_working_' + folder_token).removeClass('hide');
            $('.close_button_' + folder_token).addClass('hide');
            console.log("status=" + data.status.toString() + " - message=" + data["message"])
            if (data.status == true) {
                if (data['message'] == "Procesado") {

                    clearInterval(window.timerFirma);
                    var cc = window.tiempo_espera_tocapu_subida;
                    window.timerFirma = setInterval(function () {

                        $(".sign_counter_" + folder_token).html("Procesando <b class='green'>" + data["filename"] + "</b>, por favor espere<br>" + cc + "s")
                        if (cc == 0) {
                            $(".sign_working_" + folder_token).addClass("hide");
                            $(".close_button_" + folder_token).removeClass("hide");
                            $(".descargar-tocapu-firmador").removeClass("hide");
                            $(".firmar-todo-button-working").removeClass("disabled");
                            $("#sign_list_" + folder_token + " dd").each(function (i, dd) {
                                $(dd).find(".icon_load").addClass("hide");
                                $(dd).find(".sign_document_message").text("")
                            });
                            $('.sign_message_' + folder_token).text("Oops! Parece que no pudiste Firmar el documento, puedes intentar de nuevo");
                            clearInterval(window.timerFirma)
                        }
                        ;
                        if (cc < 0)
                            clearInterval(window.timerFirma)
                        cc--;
                    }, 1400)


                    clearInterval(window.timerFirmaAll);
                    var c = window.tiempo_espera_tocapu_subida;
                    window.timerFirmaAll = setInterval(function () {
                        $('.sign_document_all_' + document_token + " > .sign_document_message").html("Firmando: " + c + "s")
                        if (c == 0) {
                            $('.sign_document_all_' + document_token + " > .icon_load").addClass('hide');
                            $('.sign_document_all_' + document_token + " > .icon_ok").addClass('hide');
                            $('.sign_document_all_' + document_token + " > .icon_error").removeClass('hide');
                            $('.sign_document_all_' + document_token + " > .sign_document_message").html("Parece que no se pudo firmar")
                            clearInterval(window.timerFirmaAll)
                        }
                        ;
                        if (c < 0)
                            clearInterval(window.timerFirmaAll)
                        c--;
                    }, 1400)
                } else {
                    clearInterval(window.timerFirmaAll)
                    $(".sign_counter_" + folder_token).html("Firmado: <b class='green'>" + data["filename"] + "</b>, Esperando siguiente...<br>")
                    $('.sign_document_all_' + document_token).addClass('green');
                    $('.sign_document_all_' + document_token + " > .icon_load").addClass('hide');
                    $('.sign_document_all_' + document_token + " > .icon_ok").removeClass('hide');
                    $('.sign_document_all_' + document_token + " > .icon_error").addClass('hide');
                    $('.sign_document_all_' + document_token + " > .sign_document_message").text(data['message']);
                }

            } else {
                $(".sign_counter_" + folder_token).html("Con Error: <b class='green'>" + data["filename"] + "</b>, Esperando siguiente...<br>")
                $('.sign_document_all_' + document_token).addClass('red');
                $('.sign_document_all_' + document_token + " > .icon_load").addClass('hide');
                $('.sign_document_all_' + document_token + " > .icon_ok").addClass('hide');
                $('.sign_document_all_' + document_token + " > .icon_error").removeClass('hide');
                $('.sign_document_all_' + document_token + " > .sign_document_message").text(data['message']);
            }

            var listaDocumentos = [];
            var count = $("#sign_list_" + folder_token + " dd").length;
            $("#sign_list_" + folder_token + " dd").each(function (i, dd) {
                if ($(dd).hasClass("green"))
                    listaDocumentos.push("OK");
                else if ($(dd).hasClass("red"))
                    listaDocumentos.push(null)

            });
            if (listaDocumentos.length == count) {
                var OK = true;
                for (var i = 0; i <= listaDocumentos.length - 1; i++) {
                    if (listaDocumentos[i] == null)
                        OK = false;
                }
                if (OK) {
                    clearInterval(window.timerFirmaAll)
                    clearInterval(window.timerFirma)
                    $(".sign_message_" + folder_token).text("Proceso terminado correctamente")
                    $(".sign_working_" + folder_token).addClass("hide");
                    $(".descargar-tocapu-firmador").addClass("hide");
                    console.log("OK firma completa folder_token=" + folder_token + " - document=" + document_token);
                    $('.sign_working_' + folder_token).addClass('hide');
                    $('.sign_error_' + folder_token).addClass('hide');
                    $('.sign_ok_' + folder_token).removeClass('hide');
                } else {
                    clearInterval(window.timerFirmaAll)
                    clearInterval(window.timerFirma)
                    $(".sign_working_" + folder_token).addClass("hide");
                    $(".descargar-tocapu-firmador").addClass("hide");
                    console.log("ERROR firma completa folder_token=" + folder_token + " - document=" + document_token);
                    $('.sign_working_' + folder_token).addClass('hide');
                    $('.sign_error_' + folder_token).removeClass('hide');
                    $('.sign_ok_' + folder_token).addClass('hide');
                    $(".sign_message_" + folder_token).text("Proceso terminado con errores, verifica")
                }
            }
        } else {

            if (data.status == true) {
                console.log(data['message'])
                if (data['message'] == "Procesado") {
                    clearInterval(window.timerFirma);
                    var c = window.tiempo_espera_tocapu_subida;
                    window.timerFirma = setInterval(function () {
                        $(".sign_counter_" + document_token).html("Documento descargado. Esperando Firma del documento <br>" + c + "s")
                        if (c == 0) {
                            $(".sign_working_" + document_token).addClass("hide");
                            $(".close_button_" + document_token).removeClass("hide");
                            $(".descargar-tocapu-firmador").removeClass("hide");
                            $(".firmar").removeClass("hide");
                            $("#ubicacion_firma_" + document_token).removeClass("hide");
                            $(".custom_sign").removeClass("hide");
                            $(".limpiarFirma").removeClass("hide");
                            $('.sign_message_' + document_token).text("Oops! Parece que no pudiste Firmar el documento, puedes intentar de nuevo");
                            clearInterval(window.timerFirma)
                        }
                        ;
                        if (c < 0)
                            clearInterval(window.timerFirma)
                        c--;
                    }, 1400)

                } else {
                    $('.sign_action_' + document_token).addClass('hide');
                    $('.sign_message_' + document_token).text(data['message']);
                    $('.close_button_' + document_token).addClass('hide');
                    $('.sign_ok_' + document_token).removeClass('hide');
                    $('.sign_error_' + document_token).addClass('hide');
                    $('.sign_document_' + document_token).addClass('green');
                    $('.sign_working_' + document_token).addClass('hide');

                    if ($("#canvas_pdf_custom_" + document_token).length > 0) {
                        GetUrl(document_token, (data) => {
                            if (data.success) {
                                var tipo_pagina = $("#procesar_firma_visual_" + document_token).attr("data-tipo-pagina");
                                var pagina = $("#procesar_firma_visual_" + document_token).attr("data-pagina");
                                var max = $("#pagina_firma_" + document_token).attr("max");
                                if (parseInt(tipo_pagina) == 1)
                                    pagina = tipo_pagina;
                                else if (parseInt(tipo_pagina) == -1)
                                    pagina = max;
                                console.log(pagina)

                                cargarpdf(data.download_url, "canvas_pdf_custom_" + document_token, parseInt(pagina));
                            }
                        })

                    }


                }

            } else {
                clearInterval(window.timerFirma);
                $(".sign_working_" + document_token).addClass("hide");
                $(".close_button_" + document_token).removeClass("hide");

                $('.sign_action_' + document_token).addClass('hide');
                $('.sign_message_' + document_token).text(data['message']);
                $('.sign_ok_' + document_token).addClass('hide');
                $('.sign_error_' + document_token).removeClass('hide');
                $('.sign_document_' + document_token).addClass('red');
            }
        }
    }
});

