
window.download_folder_events = function(){
    $(".form-ajax-download-folder").on("submit", function (e) {
        var token = $(this).data("token")
        // Prevenir submit del formulario
        e.preventDefault();
        e.stopImmediatePropagation();
        // Ejecutar descarga
        console.log("form-ajax-download-folder submit")
        $("#download-folder-status-" + token).empty()
        $("#download-folder-status-" + token).foundation("open");
        $.ajax({
            url: $(this).attr('action'),
            data: $(this).serialize(),
            type: "POST",
            dataType: "json",
            success: function (response) {
                $('.loading-page-' + token).addClass('hide');
                html = "<h2 class='text-center'>Descargar Carpeta</h2>"
                if (response.success) {
                    var job_id = response.job_id;
                    var intervalName = "download-folder-job_id-" + job_id;
                    $("#download-folder-status-" + token).empty().addClass("download-folder-" + job_id)
                    html += "<div class='text-center green lead'><i class='fa fa-refresh fa-spin'></i> Procesando <span class='download-folder-porcentaje-" + token + "'>0%...</span></div>"
                    html += "<p class='text-center'>No cerrar ni actualizar esta ventana por favor</p>"
                    window[intervalName] = setInterval(function () {
                        getDownloadFolderJobStatus(job_id, intervalName,token);
                    }, 500);

                } else {
                    html += "<p class='text-center red lead'><i class='fa fa-times'></i> " + response.message + " </p>"
                    html += "<p class='text-center'><a href='#' class='button secondary' data-close>Cerrar</a></p>"
                }
                $("#download-folder-status-" + token).empty().append(html)
            },
            error: function (xhr, textStatus, errorThrown) {
                console.log("ajax error");
                $('.loading-page-' + token).removeClass('hide');
                html = "<p class='text-center red lead'><i class='fa fa-times'></i> error inesperado </p>"
                html += "<p class='text-center'><a href='#' class='button secondary' data-close>Cerrar</a></p>"
                $("#download-folder-status-" + token).empty().append(html)
            }
        });
        return false;
    });

    function getDownloadFolderJobStatus(jobId, intervalName, token) {
        $.ajax({
            url: "/download_folder_status",
            dataType: "json",
            data: {
                job_id: jobId
            }
        }).done(function (response, status, ajaxOpt) {

            if (status === "success") {
                var percentage = response.percentage || 0;
                $(".download-folder-porcentaje-" + token).text(percentage + "%");
                if (response.status === "complete") {
                    setTimeout(function () {
                        clearInterval(window[intervalName]);
                        delete window[intervalName];
                        html = ""
                        html = "<h2 class='text-center'>Descargar Carpeta</h2>"
                        html += "<p class='text-center green lead'><i class='fa fa-check'></i> Completado</p>"
                        html += "<p class='text-center green lead'><a href='" + response.url + "' class='button large' target='_blank'><i class='fa fa-download'></i> Haz clic aquí para descargar la carpeta</a></p>"
                        html += "<p class='text-center'>ó copiar el link</p>"
                        html += "<p class='text-center green'><a href='" + response.url + "' target='_blank'>" + response.url +"</a></p>"
                        html += "<p class='text-center red lead'><small>El link de descarga estará disponible sólo por 24 horas</small></p>"
                        html += "<p class='text-center'><a href='#' class='button secondary' data-close>Cerrar</a></p>"
                        $('.download-folder-' + jobId).empty().append(html)
                    }, 10);
                }
            }
            else{
                if(response == null){
                    clearInterval(window[intervalName]);
                    delete window[intervalName];
                }
            }
        }).fail(function (error) {
            console.log(error);
        });
    }
}
