window.ConsultarRucEvents = function () {
    $(".consultar_ruc").unbind().on('click', function () {
        this_form = $(this).parents("form");
        var _this = $(this);
        var numero_doc = $('.verify_this_ruc').val();
        var nombre_class = _this.data("target-nombre");
        var tipo_class = _this.data("target-tipo");
        var dir_class = _this.data("target-direccion");
        var dep_class = _this.data("target-departamento");
        var pro_class = _this.data("target-provincia");
        var dis_class = _this.data("target-distrito");

        consultar_ruc(this_form, numero_doc,
            tipo_class ? "." + tipo_class : "",
            "." + nombre_class,
            dir_class ? "." + dir_class : "",
            dep_class ? "." + dep_class : "",
            pro_class ? "." + pro_class : "",
            dis_class ? "." + dis_class : "")

    });
    $(".consultar_dni").unbind().on('click', function () {
        this_form = $(this).parents("form");
        var _this = $(this);
        var nombre_class = _this.data("target-nombre");
        var tipo_class = _this.data("target-tipo");
        var numero_doc = $('.verify_this_dni').val();
        consultar_ruc(this_form, numero_doc, "." + tipo_class, "." + nombre_class, "", "", "", "")
    });
    $(".consultar_ruc_o_dni").unbind().on('click', function () {
        this_form = $(this).parents("form");
        var _this = $(this);
        var value_class = _this.data("target-value");
        var nombre_class = _this.data("target-nombre");
        var tipo_class = _this.data("target-tipo");
        var dir_class = _this.data("target-direccion");
        var dep_class = _this.data("target-departamento");
        var pro_class = _this.data("target-provincia");
        var dis_class = _this.data("target-distrito");
        var numero_doc = $('.verify_this_ruc_dni').val();
        consultar_ruc(this_form, numero_doc,
            tipo_class ? "." + tipo_class : "",
            "." + nombre_class,
            dir_class ? "." + dir_class : "",
            dep_class ? "." + dep_class : "",
            pro_class ? "." + pro_class : "",
            dis_class ? "." + dis_class : "")
    });
}

window.ValidateRucDni = function () {
    $('.verify_this_ruc').on('keyup input load', function () {
        var target_tipo = $(this).data("target-tipo")
        var target_nombre = $(this).data("target-nombre")
        var target_direccion = $(this).data("target-direccion")
        var target_departamento = $(this).data("target-departamento")
        var target_provincia = $(this).data("target-provincia")
        var target_distrito = $(this).data("target-distrito")
        verify_this_ruc = $(this).val() || 0;
        if (verify_this_ruc.length > 0) {
            if (ruc_verify(verify_this_ruc)) {
                $("#modal-extraer-documento").foundation('open');
                $("#extraer-ruc").addClass("consultar_ruc");
                $("#extraer-ruc").data("target-tipo", target_tipo)
                $("#extraer-ruc").data("target-nombre", target_nombre)
                $("#extraer-ruc").data("target-direccion", target_direccion)
                $("#extraer-ruc").data("target-departamento", target_departamento)
                $("#extraer-ruc").data("target-provincia", target_provincia)
                $("#extraer-ruc").data("target-distrito", target_distrito)
                $("#tipo-extraer").text("Ingresaste un RUC, deseas importar los datos desde SUNAT?")
                ConsultarRucEvents();


                $('.ruc_error').hide();
                $('.ruc_correct').show();
                $('.verify_this_ruc').removeClass('with_error');
                $('.verify_this_ruc_button').removeAttr('disabled');
            } else {
                $('.ruc_error').show();
                $('.ruc_correct').hide();
                $('.verify_this_ruc').addClass('with_error');
                $('.verify_this_ruc_button').prop('disabled', 'disabled');
            }
        } else {
            $('.ruc_error').hide();
            $('.ruc_correct').hide();
            $('.verify_this_ruc_button').prop('disabled', 'disabled');
        }
    });
    $('.verify_this_ruc_dni').on('keyup input load', function () {
        var target_tipo = $(this).data("target-tipo")
        var target_nombre = $(this).data("target-nombre")
        var target_value = $(this).data("target-value")
        var target_direccion = $(this).data("target-direccion")
        var target_departamento = $(this).data("target-departamento")
        var target_provincia = $(this).data("target-provincia")
        var target_distrito = $(this).data("target-distrito")
        verify_this_ruc = $(this).val() || 0;
        if (verify_this_ruc.length > 0) {
            if (ruc_verify(verify_this_ruc)) {
                $("#modal-extraer-documento").foundation('open');
                $("#extraer-ruc").addClass("consultar_ruc_o_dni");
                $("#extraer-ruc").data("target-value", target_value)
                $("#extraer-ruc").data("target-tipo", target_tipo)
                $("#extraer-ruc").data("target-nombre", target_nombre)
                $("#extraer-ruc").data("target-direccion", target_direccion)
                $("#extraer-ruc").data("target-departamento", target_departamento)
                $("#extraer-ruc").data("target-provincia", target_provincia)
                $("#extraer-ruc").data("target-distrito", target_distrito)
                $("#tipo-extraer").text("Ingresaste un RUC, deseas importar los datos desde SUNAT?")
                ConsultarRucEvents();

                $('.ruc_error').hide();
                $('.ruc_correct').show();
                $('.ruc_correct').find("span").html('<i class="fa fa-check"></i> CORRECTO')
                $('.verify_this_ruc_dni').removeClass('with_error');
                $('.verify_this_ruc_button').removeAttr('disabled');
            } else {
                if (verify_this_ruc.length == 8) {
                    $('.ruc_error').hide();
                    $('.ruc_correct').show();
                    $('.ruc_correct').find("span").html('<i class="fa fa-check"></i> EXTRAER DNI')
                    $('.verify_this_ruc_dni').removeClass('with_error');
                    $('.verify_this_ruc_button').removeAttr('disabled');
                } else {
                    $('.ruc_error').show();
                    $('.ruc_correct').hide();
                    $('.verify_this_ruc_dni').addClass('with_error');
                    $('.verify_this_ruc_button').prop('disabled', 'disabled');
                }

            }
        } else {
            $('.ruc_error').hide();
            $('.ruc_correct').hide();
            $('.verify_this_ruc_button').prop('disabled', 'disabled');
        }
    });
    $('.verify_this_dni').on('keyup input load', function () {
        var target_tipo = $(this).data("target-tipo")
        var target_nombre = $(this).data("target-nombre")
        if ($("." + target_tipo).val() != "DNI") {
            $('.dni_error').hide();
            $('.dni_correct').hide();
            $('.verify_this_dni_button').prop('disabled', 'disabled');
            return;
        }

        var verify_this_dni = $(this).val() || 0;
        if (verify_this_dni.length > 0) {

            if (verify_this_dni.length == 8) {
                $("#modal-extraer-documento").foundation('open');
                $("#extraer-ruc").addClass("consultar_dni");
                $("#extraer-ruc").data("target-tipo", target_tipo)
                $("#extraer-ruc").data("target-nombre", target_nombre)
                $("#tipo-extraer").text("Ingresaste un DNI, deseas importar los datos desde SUNAT?")
                ConsultarRucEvents();


                $('.dni_error').hide();
                $('.dni_correct').show();
                $('.verify_this_dni').removeClass('with_error');
                $('.verify_this_dni_button').removeAttr('disabled');
            } else {
                $('.dni_error').show();
                $('.dni_correct').hide();
                $('.verify_this_dni').addClass('with_error');
                $('.verify_this_dni_button').prop('disabled', 'disabled');
            }

        } else {
            $('.dni_error').hide();
            $('.dni_correct').hide();
            $('.verify_this_dni_button').prop('disabled', 'disabled');
        }
    });
}
window.consultar_ruc = function (this_form, numero_doc, target_type, target_name, target_dir, target_dep, target_prov, target_dist) {
    $.ajax({
        url: '/consultar_ruc',
        type: 'POST',
        data: {
            authenticity_token: $('[name=authenticity_token]').val(),
            numero_doc: numero_doc,
            tipo: numero_doc.length == 8 ? 'dni' : 'ruc'
        },
        success: function (data) {
            console.log(data);
            if (data.success) {
                if (!data.denominacion) {
                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: "No se encontró ningun registro con el " + (numero_doc.length == 8 ? 'DNI' : 'RUC') + " ingresado",
                    }).show();
                    return
                }
                new Noty({
                    type: "success",
                    timeout: 3000,
                    text: (numero_doc.length == 8 ? 'DNI' : 'RUC') + " encontrado",
                }).show();
                $(target_name).val(data.denominacion);
                if (target_dir)
                    $(target_dir).val(data.direccion);
                if (target_dep)
                    (data.departamento == "-") ? $(target_dep).val("") : $(target_dep).val(data.departamento);
                if (target_prov)
                    (data.provincia == "-") ? $(target_prov).val("") : $(target_prov).val(data.provincia);
                if (target_dist)
                    (data.distrito == "-") ? $(target_dist).val("") : $(target_dist).val(data.distrito);
                if (target_type) {
                    if ($(target_type).find("option:contains('1')").length) {
                        if (numero_doc.length == 8) {
                            $(target_type).val("2")
                        } else
                            $(target_type).val("1")
                    } else if ($(target_type).find("option:contains('RUC')").length) {
                        if (numero_doc.length == 8) {
                            $(target_type).val("DNI")
                        } else
                            $(target_type).val("RUC")
                    }
                }

                if ($("#modal-extraer-documento").attr("aria-hidden") == "false") {
                    $("#modal-extraer-documento").foundation("close");
                    $("#extraer-ruc").removeClass("consultar_ruc");
                    $("#extraer-ruc").removeClass("consultar_ruc_o_dni");
                    $("#extraer-ruc").removeClass("consultar_dni");
                }
            }
            //this_form.foundation("validateForm");
        },
        error: function (e) {

        }
    })
}


