window.SelectTwo = function () {

    $('select.select2').select2({
        placeholder: function () {
            $(this).data('placeholder');
        },
        allowClear: true
    })
};

window.ActualizarPorcentaje = function(){
    setTimeout(function(){
        $.post("/refrescar-porcentaje")
    },1000);

}

window.FilePickerFolder = function (gratis, limite, bytes_gratis,bytes_pago) {
    var authenticity_token = $('#authenticity_token').val();
    var folder_id = $('#folder_id').val();
    var user_id = $('#user_id').val();
    if(!limite)
        limite = 100;
    console.log("init filepicker")
    $('#filepicker').unbind().filePicker({
        url: '/folders/upload_document_file/' + folder_id + '/' + user_id,
        data: function () {
            return {
                "authenticity_token": authenticity_token,
                "folder_id": folder_id,
                "user": user_id
            }
        },
        acceptFileTypes: /(\.|\/)(pdf)$/i,
        maxFileSize: gratis ? bytes_gratis : bytes_pago,
        plugins: ['ui', 'drop'],
        uploadMultipleLimit:2,
        uploadMultiple:false,
        messages: {
            uploadFallback: 'El navegador no soporta subir archivos.',
            minFileSize: 'El archivo no debe ser menor a :min KB.',
            maxFileSize: 'El archivo no debe ser mayor a ' + (gratis ? (bytes_gratis / 1048576) + "MB" : (bytes_pago / 1048576) + "MB"),
            postMaxSize: 'El archivo excede el límite de tamaño máximo de :max MB.',
            invalidFileType: 'El tipo de archivo no está soportado. Debe ser formato PDF.',
            error: 'Oops! Ocurrio un error.',
            abort: 'La operación fue abortada.',
            // UI plugin
            processing: 'Procesando...',
            deleteFail: 'No se puede eliminar :file.',
        },
        ui: {
            perPage: 20,
            refreshPagination: true,
            autoLoad: true
        }
    }).on('load.filepicker', function (e, data) {
        $("#load_picker").addClass("hide");
        window.limite_subida_master = parseInt(limite);
        console.log("Load")
        localStorage.setItem("upload-count", data.files.length)
        localStorage.setItem("ready_count", data.files.length)
    }).on('done.filepicker', function (e, data) {
        var token = data.files[0].token;

        if ($("#file_" + token).length > 0) {
            $("#file_" + token).remove()
        }
        else{
            var conteo = $("#count-files-folder").attr("conteo")
            $("#count-files-folder").attr("conteo", parseInt(conteo) + 1)
            $("#count-files-folder").text((parseInt(conteo) + 1) + " documentos")
        }
        $(".message").remove();
        var list = $('.files');

        // Iterate through the uploaded files
        $.each(data.files, function (i, file) {
            if (file.error) {
                list.append('<div class="message hide" key="warning"> Con error archivo ' + file.name + ' - ' + file.error + '</div>');
            } else {
                list.append('<div class="message hide" key="success"> Se subió el archivo ' + file.name + '</div>');
            }
        });

    }).on('renderupload.filepicker', function (e, data) {
        var upload_count = isNaN(parseInt(localStorage.getItem("upload-count"))) ? 0 : parseInt(localStorage.getItem("upload-count"))
        localStorage.setItem("upload-count", (upload_count + 1))
    }).on('renderdone.filepicker', function (e, data) {

        if(e.originalEvent.type == "renderdownload"){
            var upload_count = isNaN(parseInt(localStorage.getItem("upload-count"))) ? 0 : parseInt(localStorage.getItem("upload-count"))
            var ready_count = isNaN(parseInt(localStorage.getItem("ready_count"))) ? 0 : parseInt(localStorage.getItem("ready_count"))

            localStorage.setItem("ready_count", ready_count + 1)
            // SETEAR FUNCIONES LUEGO DE QUE SE RENDERIZAN TODOS Y NO UNO A UNO (LO HACE LENTO)
            if(ready_count == upload_count){
                if (data.files.length == 0) {

                    $(".files")[0].innerHTML = (
                        '<tr id="files_empty">' +
                        '<td colspan="11">' +
                        '<p style="margin:30px;" class="lead text-center">Aún no hay archivos en esta Carpeta</p>' +
                        '</td>' +
                        '</tr>'
                    )
                } else {
                    $(".files #files_empty").remove()
                }
                setTimeout(function () {
                    if (data.files.length > 0) {
                        for (var i = 0; i <= data.files.length - 1; i++) {
                            Wizard(data.files[i].token);
                        }
                    }
                    $(".pagination a").unbind().on("click", function(){
                        $("#load_picker").removeClass("hide");
                        $(".files tr").remove();
                    })
                    AsociarEntidad();
                    GuardarAsociarEntidad();
                    StopTimer();
                    CompartirPorLink();
                    ChangingPaginaFirmaSola();
                    ChangeNumeroPaginaFirmaSola();
                    MostrarPreviewParaFirmaVisual();
                    ActualizarPorcentaje();
                }, 200)
                $(document).foundation();
                console.log("foundation");
            }
        }


    }).on('fail.filepicker', function (e, data) {
        console.log(data)
        // Al mostrar estos mensajes se consume mucha memoría
        // Se habilito solo en esta función, el plugin recarga automaticamente cuando sube y no se logra ver el mensaje de error.
        try{
            new Noty({
                type: "warning",
                timeout: 7000,
                text: JSON.parse(data.xhr.responseJSON)[0],
            }).show();
        }
        catch (e){
            new Noty({
                type: "warning",
                timeout: 7000,
                text: data.xhr.responseText,
            }).show();
        }


    }).on('delete.filepicker', function (e, data) {
        data.token = data.token_file

    }).on('deletedone.filepicker', function (e, data) {
        // Al mostrar estos mensajes se consume mucha memoría
        // new Noty({
        //     type: "success",
        //     timeout: 3000,
        //     text: "Documento " + data.filename + " eliminado correctamente",
        // }).show();
        var conteo = $("#count-files-folder").attr("conteo")
        $("#count-files-folder").attr("conteo", parseInt(conteo) - 1)
        $("#count-files-folder").text((parseInt(conteo) - 1) + " documentos")

        ActualizarPorcentaje();
    }).on('deleteall.filepicker', function (e, data) {
        if (!confirm("Está seguro de eliminar TODOS los archivos?, esta acción no se puede deshacer.")) {
            return false;
        }
    }).on("renderpagination.filepicker", function(e, data){
        console.log("renderpagination")

    });

}


window.Wizard = function (token) {
    $("#wizard_" + token).smartWizard({
        selected: 0,  // Initial selected step, 0 = first step
        keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
        autoAdjustHeight: true, // Automatically adjust content height
        cycleSteps: true, // Allows to cycle the navigation of steps
        backButtonSupport: false, // Enable the back button support
        useURLhash: false, // Enable selection of the step based on url hash
        lang: {  // Language variables
            next: 'Siguiente',
            previous: 'Anterior'
        },
        toolbarSettings: {
            toolbarPosition: 'none', // none, top, bottom, both
            toolbarButtonPosition: 'right', // left, right
            showNextButton: false, // show/hide a Next button
            showPreviousButton: false // show/hide a Previous button

        },
        theme: "dots",
        anchorSettings: {
            anchorClickable: false, // Enable/Disable anchor navigation
            enableAllAnchors: false, // Activates all anchors clickable all times
            markDoneStep: true, // add done css
            enableAnchorOnDoneStep: true // Enable/Disable the done steps navigation
        },
        transitionEffect: 'fade', // Effect on navigation, none/slide/fade
        transitionSpeed: '400'
    });
}
window.WizardAll = function () {
    $("#wizard").smartWizard({
        selected: 0,  // Initial selected step, 0 = first step
        keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
        autoAdjustHeight: true, // Automatically adjust content height
        cycleSteps: true, // Allows to cycle the navigation of steps
        backButtonSupport: false, // Enable the back button support
        useURLhash: false, // Enable selection of the step based on url hash
        lang: {  // Language variables
            next: 'Siguiente',
            previous: 'Anterior'
        },
        toolbarSettings: {
            toolbarPosition: 'none', // none, top, bottom, both
            toolbarButtonPosition: 'right', // left, right
            showNextButton: false, // show/hide a Next button
            showPreviousButton: false, // show/hide a Previous button
            /*toolbarExtraButtons: [
                $('<button></button>').text('Finalizar')
                    .addClass('button')
                    .on('click', function(){
                        alert('Finsih button click');
                    }),
                $('<button></button>').text('Cancelar')
                    .addClass('button alert')
                    .on('click', function(){
                        alert('Cancel button click');
                    })
            ]*/
        },
        anchorSettings: {
            anchorClickable: true, // Enable/Disable anchor navigation
            enableAllAnchors: false, // Activates all anchors clickable all times
            markDoneStep: true, // add done css
            enableAnchorOnDoneStep: true // Enable/Disable the done steps navigation
        },
        transitionEffect: 'fade', // Effect on navigation, none/slide/fade
        transitionSpeed: '400'
    });
    /*$(".wizard_all").steps({
        headerTag: "h3",
        bodyTag: "section",
        enableAllSteps: false,
        enablePagination: false
    });*/

}
window.ChangeNotaPendiente = function () {
    $("#note_pendiente").off("click").on("click", function () {
        var _this = $(this);
        if (_this.prop("checked")) {
            $("#note_para_el_cliente").prop("checked", false);
        }
    })
    $("#note_para_el_cliente").off("click").on("click", function () {
        var _this = $(this);
        if (_this.prop("checked")) {
            $("#note_pendiente").prop("checked", false);
        }
    })
}
