$(document).on('turbolinks:load', function () {
    PrecioCertificado();

    function PrecioCertificado() {
        $(".tipo_de_certificado, .meses_de_vigencia,.tipo_de_almacen").on('change', function () {
            var tipo_de_certificado = $('.tipo_de_certificado option:selected').val();
            var meses_de_vigencia = $('.meses_de_vigencia option:selected').val();
            var tipo_de_almacen = $('.tipo_de_almacen option:selected').val();
            $.post("/certificates/precio", {
                tipo_de_certificado: tipo_de_certificado,
                meses_de_vigencia: meses_de_vigencia,
                para_firma_remota: tipo_de_almacen == "watana-almacen-firma-remota"
            }, function (data) {
                if (data.success) {
                    $(".callout.precio").show()
                    document.getElementById("result_precio_cert").innerHTML = data.precio
                } else {
                    $(".callout.precio").hide()
                    $(".precio-certificado").text("-")
                }
            });
        })
        $(".tipo_de_certificado").trigger('change')
    }

    OrganizationPresent();

    function OrganizationPresent() {
        $(".master_certificado").on('change', function () {

            var master_id = $(this).val();
            $.ajax({
                url: "/masters/get_users",
                data: {master_id: master_id},
                type: "post",
                success: function(e){
                    $(".user_certificado").find("option").remove();
                    if(e.success){
                        $(".user_certificado").append('<option value>Elegir usuario de cliente final</option>');
                        $.each(e.data, function (key, value) {
                            $(".user_certificado").append('<option value=' + value.id + '>' + `${value.full}` + '</option>');
                        });
                    }
                },
                error:function(e){

                }
            })

        });
        $(".tipo_de_certificado").on('change', function () {
            var tipo_de_certificado = $('.tipo_de_certificado option:selected').val();
            var form_organizacion = $("#form_organizacion")
            if (tipo_de_certificado == "EMPRESA") {
                form_organizacion.show()
                $(".organizacion_documento_tipo").attr("required", "required");
                $(".organizacion_documento_numero").attr("required", "required");
                $(".organizacion_denominacion").attr("required", "required");
                $(".organizacion_area").attr("required", "required");
                // $(".organizacion_direccion").attr("required", "required");
                // $(".organizacion_departamento").attr("required", "required");
                // $(".organizacion_provincia").attr("required", "required");
                // $(".organizacion_distrito").attr("required", "required");
                $(".titular_suscriptor_profesion_cargo").removeAttr("required");
                $(".titular_suscriptor_profesion_cargo").show();
            } else if (tipo_de_certificado == "PERSONA") {
                form_organizacion.hide()
                $(".organizacion_documento_tipo").removeAttr("required");
                $(".organizacion_documento_tipo").val("");
                $(".organizacion_documento_numero").removeAttr("required");
                $(".organizacion_documento_numero").val("");
                $(".organizacion_denominacion").removeAttr("required");
                $(".organizacion_denominacion").val("");
                $(".organizacion_area").removeAttr("required");
                $(".organizacion_area").val("");
                // $(".organizacion_direccion").removeAttr("required");
                // $(".organizacion_direccion").val("");
                // $(".organizacion_departamento").removeAttr("required");
                // $(".organizacion_departamento").val("");
                // $(".organizacion_provincia").removeAttr("required");
                // $(".organizacion_provincia").val("");
                // $(".organizacion_distrito").removeAttr("required");
                // $(".organizacion_distrito").val("");
                $(".titular_suscriptor_profesion_cargo").removeAttr("required");
                $(".titular_suscriptor_profesion_cargo").val("");
                $(".titular_suscriptor_profesion_cargo").hide();
            } else if (tipo_de_certificado == "PROFESIONAL") {
                form_organizacion.hide()
                $(".organizacion_documento_tipo").removeAttr("required");
                $(".organizacion_documento_tipo").val("");
                $(".organizacion_documento_numero").removeAttr("required");
                $(".organizacion_documento_numero").val("");
                $(".organizacion_denominacion").removeAttr("required");
                $(".organizacion_denominacion").val("");
                $(".organizacion_area").removeAttr("required");
                $(".organizacion_area").val("");
                // $(".organizacion_direccion").removeAttr("required");
                // $(".organizacion_direccion").val("");
                // $(".organizacion_departamento").removeAttr("required");
                // $(".organizacion_departamento").val("");
                // $(".organizacion_provincia").removeAttr("required");
                // $(".organizacion_provincia").val("");
                // $(".organizacion_distrito").removeAttr("required");
                // $(".organizacion_distrito").val("");
                $(".titular_suscriptor_profesion_cargo").attr("required", "required");
                $(".titular_suscriptor_profesion_cargo").show();
            }
        })
        $(".tipo_de_certificado").trigger('change')
    }
});
