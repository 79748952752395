window.btnContinuarPaso1_FirmaIndividual = function (e) {
    var _this = $(e);
    var token = _this.data("token");
    var firmador = _this.data("firmador");
    $("#procesar_firma_visual_" + token).attr("data-firmador", firmador);
    $("#wizard_" + token).smartWizard("next");
    $(".firma_personalizada").removeClass("hide");

    var doc_token = _this.data("token");
    $("#ubicacion_firma_" + doc_token).addClass("hide");
    GetUrl(doc_token, (data) => {
        if (data.success) {
            $(".firmar").addClass("disabled");
            cargarpdf(data.download_url, _this.data('toggle').toString().replace('document', 'canvas'), true, null, null, (count) => {

                $("#paginas_" + doc_token).removeClass("hide")
                $("#paginas_" + doc_token).text("(" + count.toString() + (count == 1 ? " página)" : " páginas)"))
                $("#pagina_firma_" + doc_token).attr("max", count);
                if (count == 1) {
                    $("#select_pagina_firma_" + doc_token).find('option').remove();
                    $("#select_pagina_firma_" + doc_token).append(`<option disabled>Seleccione página de firma</option>`);
                    $("#select_pagina_firma_" + doc_token).append(`<option value="0">Única página</option>`);
                } else {
                    $("#select_pagina_firma_" + doc_token).find('option').remove();
                    $("#select_pagina_firma_" + doc_token).append(`<option disabled>Seleccione página de firma</option>`);
                    $("#select_pagina_firma_" + doc_token).append(`<option value="1">Primera página</option>`);
                    $("#select_pagina_firma_" + doc_token).append(`<option value="2">Número de página</option>`);
                    $("#select_pagina_firma_" + doc_token).append(`<option value="-1">Última página</option>`);
                }


                setTimeout(function () {
                    $("#ubicacion_firma_" + doc_token).removeClass("hide");
                    $(".firmar").removeClass("disabled");


                }, 500)


            })
        } else {
            var load = document.getElementById("load_" + _this.data('toggle').toString().replace('document', 'canvas').toString())
            load.style.display = "none";
            new Noty({
                type: "warning",
                timeout: 3000,
                text: data.message,
            }).show();
        }
    })
}

window.btnFirmarPaso2_FirmaIndividual = function (e) {

    clearInterval(window.timerFirma);
    var _this = $(e);
    var token = _this.data('token');
    var firmador = _this.data('firmador');
    var motivo_firma = $("#motivo_firma_" + token).val();
    var canvas = document.getElementById("canvas_pdf_custom_" + token);
    var x = $("#sign_x_" + token).val();
    var y = $("#sign_y_" + token).val();
    if (x == "0" && y == "0") {
        new Noty({
            type: "warning",
            timeout: 3000,
            text: "Debe posicionar la firma, arrastre la firma hacia el PDF para ubicarla en la posición que desea.",
        }).show();
        return;
    }
    if (window.firma_desposicionada) {
        new Noty({
            type: "warning",
            timeout: 3000,
            text: "La firma sobrepasa los margenes del PDF, porfavor ubiquela dentro de los margenes del PDF",
        }).show();
        return;
    }
    var width = $("#sign_width_" + token).val();
    var height = $("#sign_height_" + token).val();
    var tipo_pagina = _this.attr("data-tipo-pagina");
    var pagina = _this.attr("data-pagina");
    if (parseInt(tipo_pagina) == 1 || parseInt(tipo_pagina) == -1)
        pagina = tipo_pagina;
    var max = $("#pagina_firma_" + token).attr("max");
    $("#paginas_" + token).text(`Página actual: ${pagina == -1 ? ("Última (" + max + ")") : pagina}`);

    $("#wizard_" + token).smartWizard("next");


    $('.descargar-tocapu-firmador').addClass("hide")
    $('.sign_message_' + token).text("");
    $(".sign_working_" + token).removeClass("hide");
    GetUrls(token, (data) => {
        if (data.success) {
            var download = data.sign_download_url;
            var upload = data.sign_upload_url;
            var demo = data.demo;
            var texto = data.texto_personalizado;


            if (firmador == "watana") {
                $("#enviar_watana_" + token).attr("data-texto", texto);
                $("#enviar_watana_" + token).attr("data-pagina", pagina);
                $(".paso-final-watana").removeClass("hide");
                $(".paso-final-tocapu").addClass("hide");
                $("#firmante_email_" + token).easyAutocomplete({
                    url: "/listado_firmantes",
                    getValue: "email"
                });

            } else {

                $(".paso-final-watana").addClass("hide");
                $(".paso-final-tocapu").removeClass("hide");
                if (demo) {
                    var url = `tocapusign:?url_logo=https://tocapu.pe/logo.png&environment=demo&from=${download}&to=${upload}` + `&vis_sig_x=${x}&vis_sig_y=${y}&vis_sig_width=${width}&vis_sig_height=${height}&vis_sig_page=${pagina}&vis_sig_text=${texto}`;
                } else {
                    var url = `tocapusign:?url_logo=https://tocapu.pe/logo.png&from=${download}&to=${upload}` + `&vis_sig_x=${x}&vis_sig_y=${y}&vis_sig_width=${width}&vis_sig_height=${height}&vis_sig_page=${pagina}&vis_sig_text=${texto}`;
                }
                window.location.href = url;
                $(".limpiarFirma").addClass("hide");
                $(".close_button_" + token).addClass("hide");
                $(".descargar-tocapu-firmador").addClass("hide");
                $(".firmar").addClass("hide");
                $("#ubicacion_firma_" + token).addClass("hide");
                var c = window.tiempo_espera_tocapu_descarga;
                clearInterval(window.timerFirma)
                window.timerFirma = setInterval(function () {
                    $(".sign_counter_" + token).html("Esperando que TocapuFirmador descargue el documento <br>" + c + "s")
                    if (c == 0) {

                        $("#ubicacion_firma_" + token).removeClass("hide");
                        $(".sign_working_" + token).addClass("hide");
                        $(".close_button_" + token).removeClass("hide");
                        $(".descargar-tocapu-firmador").removeClass("hide");
                        $(".firmar").removeClass("hide");
                        $(".limpiarFirma").removeClass("hide");
                        $('.sign_message_' + token).text("Oops! Parece que aun no tienes instalado TocapuFirmador o no diste clic en 'Abrir' en el navegador, por favor verifica e intenta de nuevo");
                        clearInterval(window.timerFirma)
                    }
                    ;
                    if (c < 0)
                        clearInterval(window.timerFirma)
                    c--;
                }, 1400)
            }

        } else {
            $(".sign_working_" + token).addClass("hide");
            new Noty({
                type: "warning",
                timeout: 3000,
                text: data.message,
            }).show();
        }
    }, motivo_firma);

}
window.btnEnviarWatana_FirmaIndividual = function (e) {
    clearInterval(window.timerFirma);
    var _this = $(e);
    var token = _this.data('token');
    var tipo = _this.attr('tipo');
    var ids = _this.attr('ids');
    var filter = _this.attr('filter');

    var firmante_email = $("#firmante_email_" + token).val();

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!firmante_email) {
        new Noty({
            type: "warning",
            timeout: 3000,
            text: "Ingrese un email donde enviar la solicitud",
        }).show();
        $("#firmante_email_" + token).focus();
        return;
    }
    if (!firmante_email.match(validRegex)) {
        new Noty({
            type: "warning",
            timeout: 3000,
            text: "Ingrese un email válido",
        }).show();
        $("#firmante_email_" + token).focus();
        return;
    }

    var c = confirm("Está seguro de enviar la solicitud de firma a Watana App - firmante: " + firmante_email)
    if(!c)
        return;
    if(tipo == "masivo"){
        $.ajax({
            url: "/enviar_watana",
            type: "POST",
            data: {
                operacion: "firmar_masivo",
                token: token,
                firmante_email: firmante_email,
                ids: ids,
                filter:filter,
                data: $("#json_todo_" + token).val()
            },
            success: function(e){
                console.log(e);
                if(e.success){
                    $("#wizard_folder_" + token).smartWizard("prev");
                    $("#wizard_folder_" + token).smartWizard("prev");
                    $("#a-step-2-" + token).closest("li").removeClass("done");
                    $("#a-step-3-" + token).closest("li").removeClass("done");
                    $("#a-step-4-" + token).closest("li").removeClass("done");
                    new Noty({
                        type: "success",
                        timeout: 6000,
                        text: "Se ha enviado la solicitud de firma al firmante: " + firmante_email + ", a la espera de que sea aprobada.",
                    }).show();
                    $("#sign_block-" + token).foundation("close")
                    setTimeout(function(){
                        window.location.href = "/firmas-watana"
                    },3000)
                }
                else{
                    new Noty({
                        type: "warning",
                        timeout: 6000,
                        text: e.mensaje,
                    }).show();
                }
            },
            error:function(e){
                new Noty({
                    type: "warning",
                    timeout: 6000,
                    text: e,
                }).show();
            }

        });
    }
    else{
        var motivo_firma = $("#motivo_firma_" + token).val();
        var x = $("#sign_x_" + token).val();
        var y = $("#sign_y_" + token).val();
        var width = $("#sign_width_" + token).val();
        var height = $("#sign_height_" + token).val();
        var pagina = _this.attr("data-pagina");
        var texto = _this.attr("data-texto");

        $.ajax({
            url: "/enviar_watana",
            data: {
                operacion: "firmar_archivo",
                token: token,
                firmante_email: firmante_email,
                x: x,
                y: y,
                width: width,
                height: height,
                pagina: pagina,
                texto: texto
            },
            type: "POST",
            success: function (e) {
                if(e.success){
                    $("#wizard_" + token).smartWizard("prev");
                    $("#wizard_" + token).smartWizard("prev");
                    $("#a-step-2-" + token).closest("li").removeClass("done");
                    $("#a-step-3-" + token).closest("li").removeClass("done");
                    $("#a-step-4-" + token).closest("li").removeClass("done");
                    new Noty({
                        type: "success",
                        timeout: 6000,
                        text: "Se ha enviado la solicitud de firma al firmante: " + firmante_email + ", a la espera de que sea aprobada.",
                    }).show();
                    $("#sign-" + token).foundation("close")
                    setTimeout(function(){
                        window.location.href = "/firmas-watana"
                    },3000)
                }
                else{
                    new Noty({
                        type: "warning",
                        timeout: 6000,
                        text: e.mensaje,
                    }).show();
                }
            },
            error: function (e) {
                new Noty({
                    type: "warning",
                    timeout: 6000,
                    text: e,
                }).show();
            }

        })
        return false;
    }


}
window.btnVolverPasos = function (e) {

    var _this = $(e);
    var token = _this.data("token");
    var page = _this.data("page");
    var masivo = _this.attr("tipo") == "masivo";
    if (page == 4) {

        $((masivo ? "#wizard_folder_" :"#wizard_") + token).smartWizard("prev");
        $((masivo ? "#wizard_folder_" :"#wizard_") + token).smartWizard("prev");
        $("#a-step-2-" + token).closest("li").removeClass("done");
        $("#a-step-3-" + token).closest("li").removeClass("done");
        $("#a-step-4-" + token).closest("li").removeClass("done");

    } else if (page == 3) {
        $((masivo ? "#wizard_folder_" :"#wizard_") + token).smartWizard("prev");
        $("#a-step-3-" + token).closest("li").removeClass("done");
    } else if (page == 2) {
        $((masivo ? "#wizard_folder_" :"#wizard_") + token).smartWizard("prev");
        $("#a-step-2-" + token).closest("li").removeClass("done");
    } else {
        $((masivo ? "#wizard_folder_" :"#wizard_") + token).smartWizard("prev");
    }
    setTimeout(function () {
        new Foundation.Equalizer($(".ecualizar"));
    }, 400);

}

window.btnValidarFirma = function (e, forzar = false) {

    if (navigator.userAgent.search("Firefox") > -1) {
        $(".firefox").removeClass("hide")
        $(".no-firefox").addClass("hide")
    } else {
        $(".firefox").addClass("hide")
        $(".no-firefox").removeClass("hide")
    }
    var _this = $(e);
    var token = _this.data("token");

    var html = `<p class="text-center"><span class="fa fa-spinner fa-3x fa-spin green"></span></p>
      <p class="text-center">Validando, por favor espere...</p>`;
    $(".validador-pdf-content-" + token).html(html);
    GetUrl(token, (data) => {
        if (data.success) {
            var download = data.download_url;
            $("#buttons_validar_" + token).find("span").remove();
            $(".resultados-" + token).css("display", "none")
            $(".loading-" + token).removeClass("hide")
            $("#content-document-" + token).hide();
            $("#status_validar_firma_" + token).removeClass("red");
            $("#status_validar_firma_" + token).text("Verificando firmas...");
            render_all_pdf(download, token)
            $.ajax({
                url: "/validar_firma",
                type: "POST",
                data: {token: token, forzar: forzar},
                success: function (e) {
                    var data = JSON.parse(e.data)
                    $("#estado_" + token).foundation("_destroy")
                    $("#estado_" + token).attr("class", e.estado[0])
                    $("#estado_" + token).attr("title", e.estado[1])
                    $("#estado_" + token + " span").attr("class", "fa fa-" + e.estado[2])
                    setTimeout(function () {

                        new Foundation.Tooltip($("#estado_" + token), {
                            allowHtml: true
                        }); // close tooltip
                        $(document).foundation();

                    }, 200);
                    $(".loading-" + token).addClass("hide")
                    console.log(data)
                    if (data.success && data.firmas) {
                        render_results_of_sign_validation_v2(data, token)
                    } else {
                        render_error_sign_validation_v2(data, token);

                    }
                },
                error: function (e) {
                    render_error_sign_validation_v2(null, token)

                }
            })
        } else {
            new Noty({
                type: "warning",
                timeout: 3000,
                text: data.message,
            }).show();
        }
    })

}

window.btnValidarOS = function () {

    var os = getOS();
    if (os == "MacOS") {
        $(".link-windows").addClass("hide");
        $(".link-mac").removeClass("hide");
        $(".no-link").addClass("hide");
    } else if (os == "Windows") {
        $(".link-mac").addClass("hide");
        $(".link-windows").removeClass("hide");
        $(".no-link").addClass("hide");
    } else {
        $(".link-mac").addClass("hide");
        $(".link-windows").addClass("hide");
        $(".no-link").removeClass("hide");
    }
    setTimeout(function () {
        new Foundation.Equalizer($(".ecualizar"));
    }, 200);
}

window.MostrarPreviewParaFirmaVisual = function () {
    $(".show-pdf-sign").unbind().on('click', function () {
        var _this = $(this);
        var doc_token = _this.data("token");
        $("#ubicacion_firma_" + doc_token).addClass("hide");
        GetUrl(doc_token, (data) => {
            if (data.success) {
                $(".firmar").addClass("disabled");
                cargarpdf(data.download_url, _this.data('toggle').toString().replace('document', 'canvas'), true, null, null, (count) => {

                    $("#paginas_" + doc_token).removeClass("hide")
                    $("#paginas_" + doc_token).text("(" + count.toString() + (count == 1 ? " página)" : " páginas)"))
                    $("#pagina_firma_" + doc_token).attr("max", count);
                    if (count == 1) {
                        $("#select_pagina_firma_" + doc_token).find('option').remove();
                        $("#select_pagina_firma_" + doc_token).append(`<option disabled>Seleccione página de firma</option>`);
                        $("#select_pagina_firma_" + doc_token).append(`<option value="0">Única página</option>`);
                    } else {
                        $("#select_pagina_firma_" + doc_token).find('option').remove();
                        $("#select_pagina_firma_" + doc_token).append(`<option disabled>Seleccione página de firma</option>`);
                        $("#select_pagina_firma_" + doc_token).append(`<option value="1">Primera página</option>`);
                        $("#select_pagina_firma_" + doc_token).append(`<option value="2">Número de página</option>`);
                        $("#select_pagina_firma_" + doc_token).append(`<option value="-1">Última página</option>`);
                    }


                    setTimeout(function () {
                        $("#ubicacion_firma_" + doc_token).removeClass("hide");
                        $(".firmar").removeClass("disabled");


                    }, 500)


                })
            } else {
                var load = document.getElementById("load_" + _this.data('toggle').toString().replace('document', 'canvas').toString())
                load.style.display = "none";
                new Noty({
                    type: "warning",
                    timeout: 3000,
                    text: data.message,
                }).show();
            }
        })
    });
}

window.CompartirPorLink = function () {
    $(".shared_link_document").unbind().on("click", function () {
        var _this = $(this);
        var token = _this.data("token");
        $.ajax({
            url: "/documents/shared/" + token,
            type: "GET",
            success: function (e) {
                console.log(e)
            }
        })
    })
}

window.ChangingPaginaFirmaSola = function () {

    $(".select_motivo_firma").unbind().on("change", function () {
        var _this = $(this);
        var token = _this.data("token");
        if (_this.val() == "-1") {
            $("#motivo_firma_" + token).removeClass("hide")
        } else {
            if (_this.val() == "0") {
                $("#motivo_firma_" + token).val("");
            } else {
                $("#motivo_firma_" + token).val(_this.val());
            }

            $("#motivo_firma_" + token).addClass("hide");
        }

    });

    $(".select_pagina_firma_sola").unbind().on("change", function () {
        var _this = $(this);
        var token = _this.data("token");
        $("#paginas_" + token).text("Cargando...");
        if (_this.val() == 2)
            $("#pagina_firma_" + token).removeClass("hide");
        else
            $("#pagina_firma_" + token).addClass("hide")
        var tipo_pagina = _this.val();
        var pagina = $("#pagina_firma_" + token).val();
        $("#procesar_firma_visual_" + token).attr("data-tipo-pagina", tipo_pagina);
        $("#procesar_firma_visual_" + token).attr("data-pagina", pagina);
        $("#sign_x_" + token).val(0);
        $("#sign_y_" + token).val(0);
        window.firma_desposicionada = true;
        $("#procesar_firma_visual_" + token).addClass("disabled");
        GetUrl(token, (data) => {
            if (data.success) {
                cargarpdf(data.download_url, "canvas_pdf_custom_" + token, true, tipo_pagina, pagina, (count) => {
                    $("#paginas_" + token).text("(" + count.toString() + (count == 1 ? " página)" : " páginas)"))
                    $("#procesar_firma_visual_" + token).removeClass("disabled");
                })
            } else {
                var load = document.getElementById("load_canvas_pdf_custom_" + token)
                load.style.display = "none";
                new Noty({
                    type: "warning",
                    timeout: 3000,
                    text: data.message,
                }).show();
            }
        })

    });
}

window.ChangeNumeroPaginaFirmaSola = function () {
    $(".numero_pagina_firma_sola").unbind().on("change", function () {
        var _this = $(this);
        var token = _this.data("token");
        $("#paginas_" + token).text("Cargando...");
        var max = _this.attr("max");
        if (_this.val()) {
            if (max) {
                if (parseInt(_this.val()) > parseInt(max)) {
                    _this.val(max);
                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: "Este documento sólo tiene " + max + (max == 1 ? " página" : " páginas"),
                    }).show();
                }
            }
            if (parseInt(_this.val()) < 1) {
                _this.val(1);
                new Noty({
                    type: "warning",
                    timeout: 3000,
                    text: "Debes escoger una página válida",
                }).show();
            }
        } else {
            _this.val(1);
            new Noty({
                type: "warning",
                timeout: 3000,
                text: "Debes escoger una página válida",
            }).show();
        }
        var tipo_pagina = $("#select_pagina_firma_" + token).val();
        if (tipo_pagina == 0)
            tipo_pagina = 1;
        $("#procesar_firma_visual_" + token).attr("data-tipo-pagina", tipo_pagina);
        $("#procesar_firma_visual_" + token).attr("data-pagina", _this.val());
        $("#sign_x_" + token).val(0);
        $("#sign_y_" + token).val(0);
        window.firma_desposicionada = true;
        $("#procesar_firma_visual_" + token).addClass("disabled");
        GetUrl(token, (data) => {
            if (data.success) {
                cargarpdf(data.download_url, "canvas_pdf_custom_" + token, true, tipo_pagina, _this.val(), (count) => {
                    $("#paginas_" + token).text("(" + count.toString() + (count == 1 ? " página)" : " páginas)"));
                    $("#procesar_firma_visual_" + token).removeClass("disabled");
                });
            } else {
                var load = document.getElementById("load_canvas_pdf_custom_" + token)
                load.style.display = "none";
                new Noty({
                    type: "warning",
                    timeout: 3000,
                    text: data.message,
                }).show();
            }
        });


    });
}

window.StopTimer = function () {
    $('.cancel_timer').unbind().on('click', function () {
        clearInterval(window.timerFirma);
    });
}
