$(document).on('turbolinks:before-render', function () {
    // Fix https://github.com/foundation/foundation-rails/issues/290
    $('html').removeClass('is-reveal-open')
})
$(document).on('turbolinks:before_cache', function () {

    try {
        $('select.select2').select2('destroy');
        $('.select-search-select2').select2('destroy');
        $('select.select2').trigger('change');


    } catch (e) {

    }
})
$(document).on('cocoon:after-insert', function () {
    console.log("cocoon:after-insert")
    SelectTwo();
});

$(document).on('cocoon:before-insert', function () {
    console.log("cocoon:before-insert")
    SelectTwo();
});


function ValidarHoraValidez() {
    $(".validar_horas_validez").unbind().on("keyup", function () {
        var _this = $(this);
        var value = parseInt(_this.val() == "" ? 1 : _this.val());
        if (value > 24)
            _this.val(24)
        else if (value < 1)
            _this.val(1)
    })
}


function ChangePaginaFirma() {
    $(".select_pagina_firma").on("change", function () {
        var _this = $(this);
        var token = _this.data("token")
        $("#preview_pagina_" + token).attr("data-tipo-pagina", _this.val());
        if (_this.val() == 2)
            $("#pagina_firma_" + token).removeClass("hide");
        else
            $("#pagina_firma_" + token).addClass("hide")
    });
}

$(document).on('turbolinks:load', function () {
    $(function () {
        $(document).ready(function () {
            $(".filter").on("change", function () {
                var filter = $(this).val();
                var type = $(this).attr('type');
                if (filter.length > 0) {
                    window.location = '?' + type + '=' + filter;
                } else {
                    window.location = '?'
                }
            });
        });
    });
    $("#firmante_email_123").easyAutocomplete({
        url: "/listado_firmantes",
        getValue: "email"
    });

    OrganizationPresent();

    function OrganizationPresent() {



        $(".tipo_de_certificado").on('change', function () {
            var tipo_de_certificado = $('.tipo_de_certificado option:selected').val();
            var form_organizacion = $("#form_organizacion")
            if (tipo_de_certificado == "EMPRESA") {
                form_organizacion.show()
                $(".organizacion_documento_tipo").attr("required", "required");
                $(".organizacion_documento_numero").attr("required", "required");
                $(".organizacion_denominacion").attr("required", "required");
                $(".organizacion_area").attr("required", "required");
                // $(".organizacion_direccion").attr("required", "required");
                // $(".organizacion_departamento").attr("required", "required");
                // $(".organizacion_provincia").attr("required", "required");
                // $(".organizacion_distrito").attr("required", "required");
                $(".titular_suscriptor_profesion_cargo").removeAttr("required");
                $(".titular_suscriptor_profesion_cargo").show();
            } else if (tipo_de_certificado == "PERSONA") {
                form_organizacion.hide()
                $(".organizacion_documento_tipo").removeAttr("required");
                $(".organizacion_documento_tipo").val("");
                $(".organizacion_documento_numero").removeAttr("required");
                $(".organizacion_documento_numero").val("");
                $(".organizacion_denominacion").removeAttr("required");
                $(".organizacion_denominacion").val("");
                $(".organizacion_area").removeAttr("required");
                $(".organizacion_area").val("");
                // $(".organizacion_direccion").removeAttr("required");
                // $(".organizacion_direccion").val("");
                // $(".organizacion_departamento").removeAttr("required");
                // $(".organizacion_departamento").val("");
                // $(".organizacion_provincia").removeAttr("required");
                // $(".organizacion_provincia").val("");
                // $(".organizacion_distrito").removeAttr("required");
                // $(".organizacion_distrito").val("");
                $(".titular_suscriptor_profesion_cargo").removeAttr("required");
                $(".titular_suscriptor_profesion_cargo").val("");
                $(".titular_suscriptor_profesion_cargo").hide();
            } else if (tipo_de_certificado == "PROFESIONAL") {
                form_organizacion.hide()
                $(".organizacion_documento_tipo").removeAttr("required");
                $(".organizacion_documento_tipo").val("");
                $(".organizacion_documento_numero").removeAttr("required");
                $(".organizacion_documento_numero").val("");
                $(".organizacion_denominacion").removeAttr("required");
                $(".organizacion_denominacion").val("");
                $(".organizacion_area").removeAttr("required");
                $(".organizacion_area").val("");
                // $(".organizacion_direccion").removeAttr("required");
                // $(".organizacion_direccion").val("");
                // $(".organizacion_departamento").removeAttr("required");
                // $(".organizacion_departamento").val("");
                // $(".organizacion_provincia").removeAttr("required");
                // $(".organizacion_provincia").val("");
                // $(".organizacion_distrito").removeAttr("required");
                // $(".organizacion_distrito").val("");
                $(".titular_suscriptor_profesion_cargo").attr("required", "required");
                $(".titular_suscriptor_profesion_cargo").show();
            }
        })
        $(".tipo_de_certificado").trigger('change')
    }


    if ($(".margenes").length > 0) {
        $(".margenes").each(function () {
            var _this = $(this);
            var id = _this.attr("id");
            var value = _this.val();
            setMarginsToPreview(id, value)
        })
    }
    $(".margenes").on("input keyup change", function () {
        var _this = $(this);
        var id = _this.attr("id");
        var value = _this.val();
        setMarginsToPreview(id, value)
    })

    function setMarginsToPreview(id, value) {
        value = parseInt(value / 3)
        if (id.indexOf("superior") > 0) {
            $("#margen_preview").css("padding-top", value + "px")
        }
        if (id.indexOf("inferior") > 0) {
            $("#margen_preview").css("padding-bottom", value + "px")
        }
        if (id.indexOf("izquierdo") > 0) {
            $("#margen_preview").css("padding-left", value + "px")
        }
        if (id.indexOf("derecho") > 0) {
            $("#margen_preview").css("padding-right", value + "px")
        }
    }

    setTimeout(function () {
        markjs();
    }, 600)
    $(".mark_search").on("input", markjs);

    $.ajax({
        url: "/yanapa/eventos",
        type: "get",
        success: function (e) {
            if (e.success) {
                $("#eventos_programados").html("");
                $.each(e.eventos, function (i, event) {
                    var html = `<div class="news ${event.tipo == "MANTENIMIENTO PROGRAMADO" ? "warning" : "alert"}">
                                    <div class="text-center">${event.descripcion}</div>
                                </div>`;
                    $("#eventos_programados").append(html);
                });
            }
        },
        error: function (e) {

        }
    })
    ChangeNotaPendiente();
    download_folder_events();

    function loadAttachments(token) {
        var authenticity_token = $('#authenticity_token_' + token).val();
        var modelo = $('#modelo_' + token).val();
        var modelo_token = $('#modelo_token_' + token).val();

        try {
            $('#drop-zone-attachments_' + token).filePicker({
                url: 'attachments/upload',
                data: function () {
                    return {
                        "authenticity_token": authenticity_token,
                        "modelo": modelo,
                        "modelo_token": modelo_token
                    }
                },
                autoLoad: true,
                acceptFileTypes: /(\.|\/)(pdf)$/i,
                maxFileSize: 52428800, //(= 50MB)
                plugins: ['ui', 'drop'],
                messages: {
                    uploadFallback: 'El navegador no soporta subir archivos.',
                    minFileSize: 'El archivo no debe ser menor a :min KB.',
                    maxFileSize: 'El archivo no debe ser mayor a 50MB',
                    postMaxSize: 'El archivo excede el límite de tamaño máximo de :max MB.',
                    invalidFileType: 'El tipo de archivo no está soportado. Debe ser formato PDF.',
                    error: 'Oops! Ocurrio un error.',
                    abort: 'La operación fue abortada.',
                    // UI plugin
                    processing: 'Procesando...',
                    deleteFail: 'No se puede eliminar :file.',
                },
                ui: {
                    perPage: 20,
                    autoLoad: true
                }
            }).on('done.filepicker', function (e, data) {
                console.log("done")
            }).on('renderdone.filepicker', function (e, data) {
                if (data.files.length > 0) {
                    for (var i = 0; i <= data.files.length - 1; i++) {
                        Wizard(data.files[i].token);
                    }
                }

                AsociarEntidad();
                GuardarAsociarEntidad();
                StopTimer();
                ChangingPaginaFirmaSola();
                ChangeNumeroPaginaFirmaSola();
                MostrarPreviewParaFirmaVisual();
                $(document).foundation();
                console.log("renderdone")
            }).on('fail.filepicker', function (e, data) {
                console.log(data)
                new Noty({
                    type: "warning",
                    timeout: 3000,
                    text: "No se pudieron cargar los documentos, intente mas tarde",
                }).show();
            }).on('delete.filepicker', function (e, data) {
                data.token = data.token_file
            }).on('deletedone.filepicker', function (e, data) {
                new Noty({
                    type: "success",
                    timeout: 3000,
                    text: "Archivo " + data.filename + " eliminado correctamente",
                }).show();
            }).on('deleteall.filepicker', function (e) {
                if (!confirm("Está seguro de eliminar TODOS los archivos?, esta acción no se puede deshacer.")) {
                    return false;
                }
            });
        } catch (e) {

        }

    }


    $(".load_picker").on("click", function () {
        var _this = $(this);
        loadAttachments(_this.data("token"))
    });
    $(function () {
        if ($("#download_keyword").length > 0) {
            document.getElementById('download_keyword').addEventListener('click', function (event) {
                if (this.classList.contains('disabled')) {
                    event.preventDefault();
                } else {
                    var password_pfx = document.getElementById('keyword_confirmation').value;
                    var filename = $("#download_keyword").attr("filename");
                    filename = filename + "-TOCAPU.txt";
                    download(password_pfx, filename, "text/plain");
                }
            });
        }
    });

    WizardAll();


    $(".tipo_firma_wizard_todo").on("click", function () {
        var _this = $(this);
        var token = _this.data("token");
        $("#wizard_folder_" + token).smartWizard("next");

    });
    $(".firmar_personalizada_todo").on("click", function () {
        $(".firma_personalizada_todo").removeClass("hide");
    });

    $(".volver-primer-step-todo").on("click", function () {
        console.log("volver")
        var _this = $(this);
        var token = _this.data("token");
        var page = _this.data("page");
        if (page == 4) {

            $("#wizard_folder_" + token).smartWizard("prev");
            $("#wizard_folder_" + token).smartWizard("prev");
            $("#a-step-2-" + token).closest("li").removeClass("done");
            $("#a-step-3-" + token).closest("li").removeClass("done");
            $("#a-step-4-" + token).closest("li").removeClass("done");

        } else if (page == 3) {
            $("#wizard_folder_" + token).smartWizard("prev");
            $("#a-step-3-" + token).closest("li").removeClass("done");
        } else if (page == 2) {
            $("#wizard_folder_" + token).smartWizard("prev");
            $("#a-step-2-" + token).closest("li").removeClass("done");
        } else {
            $("#wizard_folder_" + token).smartWizard("prev");
        }


    })

    $('.password_strong').keyup(function (e) {
        ValidarPassword($(this).val(), $("#keyword_confirmation").val());
        return true;
    });

    $('#keyword_confirmation').keyup(function (e) {
        ValidarPassword($("#keyword").val(), $("#keyword_confirmation").val());
        return true;
    });


    $(".procesar-firma-todos").on("click", function () {
        var _this = $(this);
        var token = _this.attr("token");
        var firmador = _this.attr("data-firmador");
        var token_firma = _this.attr("data-token");
        var select_all = $("#select_all_" + token)[0].checked
        var ids = []
        if (select_all && $("#sign_list_visual_" + token + " > tbody > tr").length > 0) {
            ids.push(-1)
        } else {
            $("#sign_list_visual_" + token + " > tbody > tr").each(function () {
                var _tr = $(this);
                var checked = _tr.find(".multiselector_" + token).prop("checked")
                console.log(checked)
                if (checked)
                    ids.push(_tr.find(".multiselector_" + token).data("id"))
            });
            if(ids.length == 0){
                NotyError("Por favor seleccione al menos un documento para firmar")
                return;
            }
        }
        IrAlPasoFinalFirmarTodo(_this)
        var filter =  $("#select_filtro_documentos_" + token).val()
        GetUrlCsv(token_firma, ids,filter, (res) => {
            if (res.success) {
                var data = [];
                var tipo_pagina_general = parseInt($("#select_pagina_firma_" + token).val());
                var posicion_general = parseInt($("#select_posicion_firma_" + token).val());
                $(".numero_pagina_firma").each(function () {
                    if (!$(this).val()) {
                        $(this).val(1)
                    }
                })
                var item_general = {
                    t: "G",
                    p: tipo_pagina_general,
                    l: posicion_general
                }

                data.push(item_general);
                $("#sign_list_visual_" + token + " > tbody > tr").each(function () {
                    var _tr = $(this);
                    var id = _tr.data("id");
                    var checked = _tr.find(".multiselector_" + token).prop("checked")
                    if (!checked) {
                        $("#sign_list_" + token + " > .list_final" + id).remove();
                        return;
                    }

                    var doc_token = _tr.data("token");
                    var tipo_pagina = parseInt($("#select_pagina_firma_" + doc_token).val());
                    var pagina = parseInt($("#pagina_firma_" + doc_token).val());
                    var max_page = parseInt($("#pagina_firma_" + doc_token).attr("max"));
                    var width_page = parseInt($("#pagina_firma_" + doc_token).attr("data-width"));
                    var height_page = parseInt($("#pagina_firma_" + doc_token).attr("data-height"));
                    var num_page = 1;
                    var posicion = parseInt($("#select_posicion_firma_" + doc_token).val());
                    var x = 0;
                    var y = 0;
                    var width_sign = parseInt(width_page / 3)
                    if (tipo_pagina == 0)
                        tipo_pagina = tipo_pagina_general;
                    if (tipo_pagina != tipo_pagina_general || posicion != posicion_general) {
                        if (tipo_pagina == 1)
                            num_page = 1;
                        else if (tipo_pagina == 2) {
                            num_page = pagina;
                        } else if (tipo_pagina == -1)
                            num_page = max_page;

                        if (posicion == 1) {
                            x = 0;
                            y = height_page - 50;
                        } else if (posicion == 2) {
                            x = parseInt((width_page / 2) - (width_sign / 2));
                            y = height_page - 50;
                        } else if (posicion == 3) {
                            x = parseInt(width_page - width_sign);
                            y = height_page - 50;
                        } else if (posicion == 4) {
                            x = 0;
                            y = 0;
                        } else if (posicion == 5) {
                            x = parseInt((width_page / 2) - (width_sign / 2));
                            y = 0;
                        } else if (posicion == 6) {
                            x = parseInt(width_page - width_sign);
                            y = 0;
                        }

                        var item = {
                            w: parseInt(width_sign),
                            h: 50,
                            t: id,
                            p: num_page,
                            x: parseInt(x) + 1,
                            y: parseInt(y) + 1
                        }
                        data.push(item);
                    }
                });

                if(firmador == "watana"){
                    $("#enviar_watana_" + token).attr("tipo","masivo");
                    $("#enviar_watana_" + token).attr("filter",filter);
                    $("#enviar_watana_" + token).attr("ids", JSON.stringify(ids));
                    console.log(data)
                    $("#json_todo_" + token).val(JSON.stringify(data));
                    $("#firmante_email_" + token).easyAutocomplete({
                        url: "/listado_firmantes",
                        getValue: "email"
                    });
                }
                else{
                    var json = JSON.stringify(data);
                    json = Buffer.from(json).toString("base64");
                    var download_csv_url = res.download_csv_url;
                    var demo = res.demo;
                    if (demo) {
                        var url_final = `tocapusign:?url_logo=https://tocapu.pe/logo.png&environment=demo&batch_csv=${download_csv_url}/${encodeURIComponent(json)}`;
                    } else {
                        var url_final = `tocapusign:?url_logo=https://tocapu.pe/logo.png&batch_csv=${res.download_csv_url}/${encodeURIComponent(json)}`;
                    }
                    //var url_final = `${res.download_csv_url}/${encodeURIComponent(json)}`;
                    window.location.href = url_final;
                }

            }
        })
    });


    $(".aplicar_posicion_todos").on("click", function () {

        var _this = $(this);
        var token = _this.data("token");
        var pagina = $("#select_pagina_firma_" + token).val();
        if (pagina == 0)
            pagina = 1;
        var posicion = $("#select_posicion_firma_" + token).val();
        if (pagina == 2)
            $(".numero_pagina_firma").removeClass("hide")
        else
            $(".numero_pagina_firma").addClass("hide")

        $(".select_pagina_firma").each(function () {
            if ($(this).find("option").length != 2)
                $(this).val(pagina)


        })
        $(".select_posicion_firma").each(function () {
            $(this).val(posicion)
        })
        new Noty({
            type: "success",
            timeout: 3000,
            text: "Se aplicó a todos los documentos",
        }).show();

    });

    function cargar_num_paginas_en_firma_visible(token) {
        $(".procesar-firma-todos").addClass("disabled");

        var count_docs = $("#sign_list_visual_" + token + " > tbody > tr").length;
        var i = 1;

        $(".sign_working_" + token).addClass("hide");
        $(".close_button_" + token).removeClass("hide");
        $(".descargar-tocapu-firmador").removeClass("hide");
        clearInterval(window.timerFirma)

        $("#sign_list_visual_" + token + " > tbody > tr").each(function () {
            var _tr = $(this);
            var doc_token = _tr.data("token");

            $("#load_paginas_" + doc_token).removeClass("hide")
            $("#paginas_" + doc_token).addClass("hide");
            GetUrl(doc_token, (data) => {
                if (data.success) {
                    get_count_pages_pdf(data.download_url, (count, width, height) => {
                        $("#load_paginas_" + doc_token).addClass("hide")
                        $("#paginas_" + doc_token).removeClass("hide")
                        $("#paginas_" + doc_token).text("(" + count.toString() + (count == 1 ? " página)" : " páginas)"))
                        $("#pagina_firma_" + doc_token).attr("max", count);
                        $("#pagina_firma_" + doc_token).attr("data-width", width);
                        $("#pagina_firma_" + doc_token).attr("data-height", height);
                        if (count == 1) {
                            $("#select_pagina_firma_" + doc_token).find('option').remove();
                            $("#select_pagina_firma_" + doc_token).append(`<option disabled>Seleccione página de firma</option>`);
                            $("#select_pagina_firma_" + doc_token).append(`<option value="0">Única página</option>`);
                        } else {
                            $("#select_pagina_firma_" + doc_token).find('option').remove();
                            $("#select_pagina_firma_" + doc_token).append(`<option disabled>Seleccione página de firma</option>`);
                            $("#select_pagina_firma_" + doc_token).append(`<option value="1">Primera página</option>`);
                            $("#select_pagina_firma_" + doc_token).append(`<option value="2">Número de página</option>`);
                            $("#select_pagina_firma_" + doc_token).append(`<option value="-1">Última página</option>`);
                        }


                        if (i == count_docs) {
                            setTimeout(function () {
                                $(".procesar-firma-todos").removeClass("disabled");

                                $("#sign_list_visual_" + token + " .select_pagina_firma").removeClass("disabled")
                                $("#sign_list_visual_" + token + " .numero_pagina_firma").removeClass("disabled")
                                $("#sign_list_visual_" + token + " .select_posicion_firma").removeClass("disabled")
                            }, 500)
                        }
                        i++;
                    });
                } else {
                    $("#load_paginas_" + doc_token).addClass("hide")
                    $("#paginas_" + doc_token).removeClass("hide")
                    $("#paginas_" + doc_token).text(data.message)
                }
            })

        });


    };


    ChangePaginaFirma();

    ChangingPaginaFirmaSola();


    function ChangeNumeroPaginaFirma() {
        $(".numero_pagina_firma").on("change", function () {
            var _this = $(this);
            var token = _this.data("token");
            var max = _this.attr("max");
            if (_this.val()) {
                if (max) {
                    if (parseInt(_this.val()) > parseInt(max)) {
                        _this.val(max);
                        new Noty({
                            type: "warning",
                            timeout: 3000,
                            text: "Este documento sólo tiene " + max + (max == 1 ? " página" : " páginas"),
                        }).show();
                    }
                }
                if (parseInt(_this.val()) < 1) {
                    _this.val(1);
                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: "Debes escoger una página válida",
                    }).show();
                }
            } else {
                _this.val(1);
                new Noty({
                    type: "warning",
                    timeout: 3000,
                    text: "Debes escoger una página válida",
                }).show();
            }
            $("#preview_pagina_" + token).attr("data-pagina", _this.val());


        });
    }

    ChangeNumeroPaginaFirmaSola();


    ModalDobleClick();

    function ModalDobleClick() {
        $(".double_clickable_modal").dblclick(function () {
            var _this = $(this);
            var target = _this.data("target");
            var token = _this.data("token");
            var url = _this.data("url");
            AjaxCarpetaDeProyecto(token, url)
            $("#" + target).foundation("open");
        });
    }

    ModalCarpetaDeProyecto();

    function ModalCarpetaDeProyecto() {
        $(".cargar_folders").on("click", function () {
            var _this = $(this);
            var token = _this.data("token");
            var url = _this.data("url");
            AjaxCarpetaDeProyecto(token, url)

        });

    }

    function CargarListParaResultados(token, data) {
        $("#sign_list_" + token).find("dd").remove()
        $.each(data.documents, function (key, value) {
            $("#sign_list_" + token).append(`
        <dd class="list_final${value.id} sign_document_all_${value.token}"  >
          ${truncateString(value.nombre, 100)} - (${sizeFormated(value.size)})
          <span class="fa fa-spinner fa-spin green icon_load"></span>
          <span class="fa fa-check icon_ok hide"></span>
          <span class="fa fa-times icon_error hide"></span>
          <span class="sign_document_message">En espera...</span>
        </dd>`)
        });
    }

    function BindEventMultiSelector() {
        $(".multiselector").on("change", function () {
            var token = $(this).data("token")
            console.log("multi change " + token)
            $("#select_all_" + token).prop("checked", false)
        });
    }

    $(".select_all").on("change", function () {
        var _this = $(this);
        var checked = _this.is(":checked");
        var token_folder = _this.data("token")
        var returnVal = true//confirm("Seguro de " + (!checked ? "deseleccionar" : "seleccioar") + " todos?");
        //_this.prop("checked", returnVal);
        if (returnVal) {
            $("#sign_list_visual_" + token_folder + " > tbody > tr").each(function () {
                var _tr = $(this);
                console.log(checked)
                _tr.find(".multiselector_" + token_folder).prop("checked",checked)
            })
        }


    })

    $(".select_filtrar_documentos").on("change", function(){
       var _this = $(this);
        var token = _this.data("token");
        var orden = $("#select_ordenar_documentos_" + token).val();
        RenderizarDocumentosFirmaMasiva(token,_this.val(),orden)
    });
    $(".select_ordenar_documentos").on("change", function(){
        var _this = $(this);
        var token = _this.data("token");
        var filtro = $("#select_filtro_documentos_" + token).val();
        RenderizarDocumentosFirmaMasiva(token,filtro,_this.val())
    });

    $(".cargar_documents_visual").on("click", function () {
        var _this = $(this);
        var token = _this.data("token");
        var firmador = _this.data("firmador");
        $("#procesar-firma-todos-" + token).attr("data-firmador", firmador);
        RenderizarDocumentosFirmaMasiva(token)

    });

    function RenderizarDocumentosFirmaMasiva(token,filtro = "all",orden = "date_desc"){
        $("#select_all_" + token).prop("checked",true)
        $('.sign_message_' + token).text("");
        AjaxDocumentosDeCarpeta(token,filtro,orden, function (data) {
            CargarListParaResultados(token, data)
            $("#sign_list_visual_" + token + " > tbody").find("tr").remove()
            $.each(data.documents, function (key, value) {
                $("#sign_list_visual_" + token + " > tbody").append(`
                    <tr data-id="${value.id}" data-token="${value.token}" data-url="${value.url}">
<td><input type="checkbox" class="multiselector multiselector_${token}" data-token="${token}" id="check${value.id}" data-id="${value.id}" checked="checked" style="margin-top: 1rem;" /></td>
          <td class="text-left ${value.status[0]}">
           ${truncateString(value.nombre, 100)} - (${sizeFormated(value.size)})
            <span class="fa fa-spinner fa-spin red" id="load_paginas_${value.token}"></span>
            <span class="red hide" id="paginas_${value.token}"></span>
            <span class="${value.status[0]}" data-tooltip tabindex="1" title="${value.status[1]}"><i class="fa fa-${value.status[2]}"></i></span>
          </td>
          <td class="text-left">
            <div style="display:inline-flex">
            <select style="margin:0;min-width: 250px" class="select_pagina_firma" data-token="${value.token}" id="select_pagina_firma_${value.token}">
              <option value="1">Cargando páginas...</option>
            </select>
            <input style="margin:0;text-align:right" id="pagina_firma_${value.token}" value="1" data-token="${value.token}" min="1" type="number" class="hide numero_pagina_firma" placeholder="Nº" style="width: 70px" />
            </div>
            <span id="preview_pagina_${value.token}" class="link-green show-pdf-page"  data-toggle="document_pdf_sign_${value.token}" data-token="${value.token}">
              <span class="name">
               Ver página
              </span>
          </span>
            <div class="dropdown-pane text-center"  data-position="bottom" data-alignment="bottom" id="document_pdf_sign_${value.token}" data-dropdown data-auto-focus="true" data-close-on-click="true">
              <p class="text-center">Vista previa</p>
              <a class="validar_firma"  data-token="${value.token}" data-open="validsign-${value.token}"><span class="fa fa-certificate"></span> Ver completo</a>
              <span id="load_canvas_pdf_sign_${value.token}" class="fa fa-spinner fa-spin" style="font-size: 40px;text-align: center;width: 100%;color:#00AA38"></span>
              <canvas id="canvas_pdf_sign_${value.token}" style="width:260px;display:none"></canvas>
            </div>
            
          </td>
          <td class="text-left">
            <select style="margin:0;min-width: 250px" class="select_posicion_firma" id="select_posicion_firma_${value.token}">
              <option disabled>Posición de firma</option>
              <option value="1">Superior izquierda</option>
              <option value="2">Superior medio</option>
              <option value="3">Superior derecha</option>
              <option value="4">Inferior izquierda</option>
              <option value="5">Inferior medio</option>
              <option value="6">Inferior derecha</option>
            </select>
          </td>        
        </tr>`)
            });
            BindEventMultiSelector();
            setTimeout(function () {
                cargar_num_paginas_en_firma_visible(token);
                MostrarPdfPorPaginaClick();
                ChangePaginaFirma();
                ChangeNumeroPaginaFirma();
                $(document).foundation();
            }, 100);
        });
    }

    function AjaxCarpetaDeProyecto(token, url) {
        $("#loading_folders_" + token).removeClass("hide");
        $("#table_folders_" + token + " tbody").empty();
        $.ajax({
            url: "/projects/get_folders_ajax",
            data: {project: token},
            type: "POST",
            success: function (data) {
                $("#loading_folders_" + token).addClass("hide");
                if (data.success) {
                    if (data.folders.length > 0) {
                        $("#table_folders_" + token).removeClass("hide");
                        $.each(data.folders, function (key, value) {
                            $("#table_folders_" + token + " tbody").append(`
                                    <tr>
                                        <td>
                                             <div style="display: inline-flex">
                                                  <span class="fa fa-folder grey_icon" style="margin-right:5px"></span>
                                                  <div style="line-height: 1em">
                                                    ${value.nombre}
                                                  </div>
                                              </div>
                                        </td>
                                        <td>
    
                                                ${value.compartido ? '<small class="link-grey">[Compartido]</small>' : ''}
    
                                          </td>
                                          <td>
                                            ${value.propietario}
                                          </td>
                                          <td>
                                           ${value.modificado}
                                          </td>
                                           <td>
                                           ${value.archivos}
                                          </td>
                                          <td><a href="${value.url}">Ver Carpeta</a></td>
                                    </tr>
                                `);
                        });
                    } else {
                        $("#table_folders_" + token).addClass("hide");
                        $("#folders_message_" + token).html(`<br>Este proyecto no tiene carpetas, puedes crear la primera <a href="${url}">aquí</a><br><br>`)
                    }

                } else {
                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: data.message,
                    }).show();
                }
            },
            error: function (e) {

                $("#loading_folders_" + token).addClass("hide");
                console.log(JSON.stringify(e));
            }

        })
    }

    function AjaxDocumentosDeCarpeta(token,filtro = "all",orden = "date_desc", renderFunc) {
        $("#loading_documents_" + token).removeClass("hide");
        $("#table_documents_" + token).empty();
        $.ajax({
            url: "/folders_public/get_documents_ajax",
            data: {folder: token, filtro: filtro, orden: orden},
            type: "POST",
            success: function (data) {
                $("#loading_documents_" + token).addClass("hide");
                if (data.success) {
                    renderFunc(data);

                } else {
                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: data.message,
                    }).show();
                }
            },
            error: function (e) {

                $("#loading_documents_" + token).addClass("hide");
                console.log(JSON.stringify(e));
            }

        })
    }

    GuardarAsociarEntidad();

    AsociarEntidad();


    EnviarCarpeta();


    FirmarTodo();
    StopTimer();


    function FirmarTodo() {
        $('.firmar-todo-modal-working').on('click', function () {
            var token = $(this).attr("token");
            $("#sign_list_" + token).attr("working", token);
        });
        $('.firmar-todo-button-working').on('click', function () {

        });
        $('.firmar-todo-button-close').on('click', function () {
            var token = $(this).attr("token");
            $("#sign_list_" + token).removeAttr("working");
        });
    }

    function IrAlPasoFinalFirmarTodo(_this){
        var token = _this.attr("token");
        var firmador = _this.attr("data-firmador");
        if(firmador == "watana"){
            $(".paso-final-watana").removeClass("hide");
            $(".paso-final-tocapu").addClass("hide");
            $("#wizard_folder_" + token).smartWizard("next");
        }
        else{
            $(".paso-final-watana").addClass("hide");
            $(".paso-final-tocapu").removeClass("hide");
            $('.sign_message_' + token).text("");
            $("#wizard_folder_" + token).smartWizard("next");
            _this.addClass("disabled")


            $(".sign_working_" + token).removeClass("hide");
            $(".close_button_" + token).addClass("hide");
            $(".descargar-tocapu-firmador").addClass("hide");
            var c = window.tiempo_espera_tocapu_descarga;
            clearInterval(window.timerFirma)
            window.timerFirma = setInterval(function () {
                $(".sign_counter_" + token).html("Esperando que TocapuFirmador descargue el bloque de archivos, por favor espere <br>" + c + "s")

                if (c == 0) {
                    _this.removeClass("disabled")
                    $(".sign_working_" + token).addClass("hide");
                    $(".close_button_" + token).removeClass("hide");
                    $(".descargar-tocapu-firmador").removeClass("hide");
                    $("#sign_list_" + token + " dd").each(function (i, dd) {
                        $(dd).find(".icon_load").addClass("hide");
                        $(dd).find(".sign_document_message").text("")
                    });
                    $('.sign_message_' + token).text("Oops! Parece que aun no tienes instalado TocapuFirmador o no diste clic en 'Abrir' en el navegador, por favor verifica e intenta de nuevo");
                    clearInterval(window.timerFirma)
                }
                ;
                if (c < 0)
                    clearInterval(window.timerFirma)
                c--;
            }, 1400)
        }


    }

    SelectTwo();
    ValidateRucDni();


    ConsultarRucEvents();


    function MostrarPdfPorPaginaClick() {
        $(".show-pdf-page").on('click', function () {
            var _this = $(this);
            var token = _this.data("token");
            if (_this.attr("data-tipo-pagina") == 2) {
                if (!_this.attr("data-pagina")) {
                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: "Ingrese el número de página. Por defecto se mostrará la primera",
                    }).show();
                    _this.attr("data-pagina", 1)
                }
            }
            GetUrl(token, (data) => {
                if (data.success) {
                    cargarpdf(data.download_url, _this.data('toggle').toString().replace('document', 'canvas'), false, _this.attr("data-tipo-pagina"), _this.attr("data-pagina"))
                } else {
                    var load = document.getElementById("load_" + _this.data('toggle').toString().replace('document', 'canvas').toString())
                    load.style.display = "none";
                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: data.message,
                    }).show();
                }
            });

        });
    }

    MostrarPreviewParaFirmaVisual();


    $('.double_clickable_row').dblclick(function () {
        var _this = $(this);
        if (_this.data('blank') == true)
            window.open(_this.data('url'), '_blank');
        else
            window.location.href = _this.data('url');
    });

    $(".nested-form a.add_fields").data("association-insertion-position", 'before').data("association-insertion-node", 'this');

    $('.compartir-form').on('cocoon:after-insert', function () {
        $('.compartir-form').foundation("validateForm");
    });


    $(function () {
        $(document).foundation();
        $("a.delete-file").on("click", function () {
            //console.log("aqui")

        });
    });

    show_messages();


    $(function () {
        view_password();
        $("#view_password").click(view_password);
    });

    function view_password() {
        if ($('#view_password').is(':checked')) {
            $('.password_field').attr('type', 'text');
        } else {
            $('.password_field').attr('type', 'password');
        }
    }

    PrevenirClickArriba();


    //AUTO OPEN
    $(function () {
        $('.auto_reveal_modal').each(function () {
            var auto_reveal_modal = $(this);
            if (auto_reveal_modal.length) {
                $(this).foundation('open');
            }
        });
    });

    OnlyNumbers();

    $(function () {
        view_password();
        $("#view_password").click(view_password);
    });

    function view_password() {
        if ($('#view_password').is(':checked')) {
            $('.password').attr('type', 'text');
        } else {
            $('.password').attr('type', 'password');
        }
    }

});




