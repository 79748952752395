$(document).on('turbolinks:load', function () {
    $(function () {
        $('.pagar_ahora').on('click', function (e) {

            publicKey = $(this).attr("publicKey");
            sell = $(this).attr("sell");
            moneda = $(this).attr("moneda");
            importe = $(this).attr("importe");
            description = $(this).attr("description");
            logotipo = $(this).attr("logotipo");
            title = $(this).attr("title");

            $.post("/pagando", {
                tipo: 'intentar_pago',
                sell: sell,
                moneda: moneda,
                importe: importe,
                description: description,
            }, function (data) {
                if (data.success) {

                } else {
                    alert(data.message)
                }
            });

            Culqi.publicKey = publicKey;
            Culqi.options({
                style: {
                    logo: logotipo
                }
            });
            Culqi.settings({
                title: title,
                currency: moneda,
                description: description,
                amount: importe
            });
            Culqi.open();
            e.preventDefault();
        });
        $(".confirmar-pago-button").on("click", function () {
            path = $(this).attr("path");
            location.href = path;
        });
    });
});
