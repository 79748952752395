window.AsociarEntidad = function() {
    $(".asociar_entidad").unbind().on('click', function () {
        var _this = $(this);
        var token = _this.data('token');
        var entity_id = _this.attr('data-entity');
        CargarEntidades(token, entity_id);
    });
}

window.CargarEntidades = function(token, entity) {
    if (!token)
        return;
    $.ajax({
        url: "/entities/get_ajax",
        type: "POST",
        success: function (data) {

            $("#entidad_" + token).find('option').remove();
            $("#entidad_" + token).append('<option value="-1">Seleccione una entidad</option>');
            $.each(data.entities, function (key, value) {
                $("#entidad_" + token).append('<option value=' + value.id + '>' + `${value.documento_numero} ${value.denominacion}` + '</option>');
            });
            if (entity)
                $("#entidad_" + token).val(entity);
            SelectTwo();

        },
        error: function (e) {
            console.log(JSON.stringify(e));
        }

    })
}

window.GuardarAsociarEntidad = function() {
    $(".guardar_asociar_entidad").unbind().on("click", function () {
        var _this = $(this);
        var token = _this.data('token');
        var entity_id = $("#entidad_" + token).val();
        if (entity_id <= 0 || !entity_id) {
            new Noty({
                type: "warning",
                timeout: 3000,
                text: "Selecciona una entidad",
            }).show();
            return;
        }
        $.ajax({
            url: "/entities/asociar_document",
            data: {document: token, entity_id: entity_id},
            type: "POST",
            success: function (data) {
                new Noty({
                    type: data.type,
                    timeout: 3000,
                    text: data.message,
                }).show();
                if (data.type == "success") {
                    $("#entidad_denominacion_" + token).html(data.denominacion);
                    $("#name_" + token).html(data.name);
                    $("#asociar_entidad_row_" + token).addClass("hide");
                    $("#entidad_denominacion_" + token).removeClass("hide");
                    $("#asociar_entidad_" + token).attr("data-entity", entity_id);
                    $("#document_entidad_" + token).foundation("close");
                }
            },
            error: function (e) {
                console.log(JSON.stringify(e));
            }

        })
    });
}
