window.GetUrl = function(token, funcOk) {
    $.ajax({
        url: "/obtener-url",
        type: "post",
        data: {
            token: token,
        },
        success: function (data) {
            funcOk(data);
        },
        error: function (e) {

        }

    })
}

window.GetUrlVersion = function(token, funcOk) {
    $.ajax({
        url: "/obtener-url-version",
        type: "post",
        data: {
            token: token,
        },
        success: function (data) {
            funcOk(data);
        },
        error: function (e) {

        }

    })
}

window.GetUrlCsv = function(token, ids, filter = "all", funcOk) {
    $.ajax({
        url: "/obtener-url-csv",
        type: "post",
        data: {
            token: token,
            ids: ids,
            filter: filter
        },
        success: function (data) {
            funcOk(data);
        },
        error: function (e) {

        }

    })
}

window.GetUrls = function(token, funcOk, motivo_firma = "") {
    $.ajax({
        url: "/obtener-urls",
        type: "post",
        data: {
            token: token,
            motivo_firma: motivo_firma
        },
        success: function (data) {
            funcOk(data);
        },
        error: function (e) {

        }

    })
}

window.btnDownloadVersion = function (e) {
    var _this = $(e);
    var token = _this.data("token");
    GetUrlVersion(token, (data) => {
        if (data.success) {
            window.open(data.download_url, '_blank');
        }
    })
}

window.btnForceDownload =  function (e) {
    var _this = $(e);
    var fileName = _this.data('nombre');
    GetUrl(_this.data("token"), (data) => {
        if (data.success) {
            var fileURL = data.download_url;
            if (!window.ActiveXObject) {
                var save = document.createElement('a');
                save.href = fileURL;
                save.target = '_blank';
                var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
                save.download = fileName || filename;
                if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                    document.location = save.href;
// window event not working here
                } else {
                    var evt = new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': false
                    });
                    save.dispatchEvent(evt);
                    (window.URL || window.webkitURL).revokeObjectURL(save.href);
                }
            }

            // for IE < 11
            else if (!!window.ActiveXObject && document.execCommand) {
                var _window = window.open(fileURL, '_blank');
                _window.document.close();
                _window.document.execCommand('SaveAs', true, fileName || fileURL)
                _window.close();
            }
        } else {
            new Noty({
                type: "warning",
                timeout: 3000,
                text: data.message,
            }).show();
        }
    });
};

window.btnOpenFile = function (e) {
    var _this = $(e);
    GetUrl(_this.data("token"), (data) => {
        if (data.success) {
            var fileURL = data.download_url;
            window.open(fileURL, '_blank')
        } else {
            new Noty({
                type: "warning",
                timeout: 3000,
                text: data.message,
            }).show();
        }
    });
};

window.btnPreviewPDF = function (e) {
    var _this = $(e);
    GetUrl(_this.data("token"), (data) => {
        if (data.success) {
            cargarpdf(data.download_url, _this.data('toggle').toString().replace('document', 'canvas'))
        } else {
            var load = document.getElementById("load_" + _this.data('toggle').toString().replace('document', 'canvas').toString())
            load.style.display = "none";
            new Noty({
                type: "warning",
                timeout: 3000,
                text: data.message,
            }).show();
        }
    })

}

window.btnForcePrint =  function (e) {
    var _this = $(e);
    GetUrl(_this.data("token"), (data) => {
        if (data.success) {
            var fileURL = data.download_url;
            printJS(fileURL);
        } else {
            new Noty({
                type: "warning",
                timeout: 3000,
                text: data.message,
            }).show();
        }
    });
};
