// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("jquery")
require("jqueryui")
window.limite_subida_master = 100;
import "cocoon-js";

require("@rails/ujs").start()
require("turbolinks").start()
require("select2")

require("@rails/activestorage").start()
require("channels")
require("packs/foundation.min.js")
require("packs/html2canvas.js")
require("packs/src/filepicker.js")
require("packs/src/filepicker-drop.js")
require("packs/src/filepicker-ui.js")
require("packs/src/print.min.js")
require("packs/markjs.min.js")
require("packs/init_plugins.js")
require("packs/steps")
require("packs/download_worker.js")
require("packs/custom.js")
require("packs/signing.js")
require("packs/documents_urls.js")
require("packs/pdf_canvas.js")
require("packs/utils.js")
require("packs/sells.js")
require("packs/entities_association.js")
require("packs/ruc_validations.js")
require("packs/send_folder_document.js")
require("packs/attachents.js")
require("packs/payings.js")
require("packs/certificates.js")
require("packs/dynamic_renders.js")
require("packs/jquery.easyautocomplete.min.js")
import $ from 'jquery';

// Se están incluyendo estos CDN https://cdnjs.com/libraries/pdf.js/2.2.228 en application.html.erb
//global.pdfjsLib = require('packs/src/pdf.min.js');


global.ClipboardJS = require("clipboard/dist/clipboard.min")
global.download = require('packs/src/download.js');
global.$ = jQuery;
global.Noty = require("packs/noty.js");
import 'select2'
import html2canvas from 'packs/html2canvas.js';
global.html2canvas = html2canvas;
global.timerFirma;
global.timerFirmaAll;
global.tiempo_espera_tocapu_descarga = 60;
global.tiempo_espera_tocapu_subida = 60;
global.firma_desposicionada = false;
global.m_top = 0;
global.m_bottom = 0;
global.m_left = 0;
global.m_right = 0;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
