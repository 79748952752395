$(document).on('turbolinks:load', function () {
    $("#sell-form").ready(
        $(function () {
                $('#sell-form .cantidad, #sell-form .precio_unitario, #sell-form .renovacion').on('input keyup change', function () {
                    BillUpdateTotals(this);
                    BillcalculatesTotals();
                });

                function BillUpdateTotals(elem) {
                    var $container = $(elem).parents(".sell_line");
                    var cantidad = $container.find('.cantidad').val() || 0;
                    var precio_unitario = $container.find('.precio_unitario').val() || 0;
                    var total = (parseFloat(cantidad) * parseFloat(precio_unitario));
                    var renovacion = $container.find('.renovacion').val() || "";
                    $container.find('.total').val(total.toFixed(2));

                    // pago_anual DEBE MEJORAR
                    if (renovacion == "0") {
                        $container.find('.pago_unico').val(total.toFixed(2));
                        $container.find('.pago_mensual').val("");
                        $container.find('.pago_anual').val("");
                    } else if (renovacion == "1") {
                        $container.find('.pago_unico').val("");
                        $container.find('.pago_mensual').val(total.toFixed(2));
                        $container.find('.pago_anual').val("");
                    } else if (renovacion == "12" || renovacion == "24" || renovacion == "36") {
                        $container.find('.pago_unico').val("");
                        $container.find('.pago_mensual').val("");
                        $container.find('.pago_anual').val(total.toFixed(2));
                    }

                    BillcalculatesTotals();
                }

                function BillcalculatesTotals() {

                    var total_pago_mensual = $('.sell_line .pago_mensual');
                    var sum_pago_mensual = 0.00;
                    $.each(total_pago_mensual, function (i) {
                        sum_pago_mensual += parseFloat($(total_pago_mensual[i]).val() || 0);
                    });
                    $('.total_pago_mensual').val(parseFloat(sum_pago_mensual).toFixed(2));

                    var total_pago_anual = $('.sell_line .pago_anual');
                    var sum_pago_anual = 0.00;
                    $.each(total_pago_anual, function (i) {
                        sum_pago_anual += parseFloat($(total_pago_anual[i]).val() || 0);
                    });
                    $('.total_pago_anual').val(parseFloat(sum_pago_anual).toFixed(2));

                    var total_pago_unico = $('.sell_line .pago_unico');
                    var sum_pago_unico = 0.00;
                    $.each(total_pago_unico, function (i) {
                        sum_pago_unico += parseFloat($(total_pago_unico[i]).val() || 0);
                    });
                    $('.total_pago_unico').val(parseFloat(sum_pago_unico).toFixed(2));

                    var total_primer_pago = $('.sell_line .pago_anual, .sell_line .pago_mensual, .sell_line .pago_unico');
                    var sum_primer_pago = 0.00;
                    $.each(total_primer_pago, function (i) {
                        sum_primer_pago += parseFloat($(total_primer_pago[i]).val() || 0);
                    });
                    $('.total_primer_pago').val(parseFloat(sum_primer_pago).toFixed(2));

                    var total_linea = $('.sell_line .total');
                    var sum_total = 0.00;
                    $.each(total_linea, function (i) {
                        sum_total += parseFloat($(total_linea[i]).val() || 0);
                    });
                    $('.gran_total').val(parseFloat(sum_total).toFixed(2));

                }

                $("#sell-form").on('cocoon:after-insert', function () {
                    $('.nested-fields:last .cantidad').val(1)
                    $('#sell-form .cantidad, #sell-form .precio_unitario, #sell-form .renovacion').on('input keyup change', function () {
                        BillUpdateTotals(this);
                        BillcalculatesTotals();
                    });
                    $('.nested-fields:last select.select2').select2({
                        formatNoMatches: function () {
                            return 'No existe';
                        },
                        placeholder: "Buscar"
                    });
                });

                $("#sell-form").on("cocoon:after-remove", function () {
                    BillcalculatesTotals();
                });

                $(".sell_line .remove_fields").on("click", function () {
                    var container = $(this).parents(".sell_line");
                    container.find("input").val(0);
                    BillcalculatesTotals();
                });

                TipoCotizacion();

                function TipoCotizacion() {
                    $("#sell_tipo_de_cotizacion").on("change", function () {
                        if ($(this).val() == "RESELLER") {
                            $(".sell-only-cliente-final").hide()
                            $(".sell-only-reseller").show()
                        } else if ($(this).val() == "CLIENTE-FINAL") {
                            $(".sell-only-cliente-final").show()
                            $(".sell-only-reseller").hide()
                        }
                    })
                };
                $('.cantidad').trigger('change');
                $('#sell_tipo_de_cotizacion').trigger('change');

            }
        )
    );
});
