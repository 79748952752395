window.EnviarCarpeta = function() {
    $(".enviar-folder-email").on("click", function () {
        var _this = $(this);
        var token = _this.data("token");
        var email = $("#email-" + token).val();
        if (!validateEmail(email)) {
            $("#email-" + token).focus();
            new Noty({
                type: "warning",
                timeout: 3000,
                text: "Ingrese un email válido",
            }).show();
            return;
        }
        $(".form-email-" + token).addClass("hide");
        $(".loading-email-" + token).removeClass("hide");
        $.ajax({
            url: "/enviar_carpeta_email",
            type: 'POST',
            data: {email: email, token: token},
            success: function (data) {
                $(".form-email-" + token).removeClass("hide");
                $(".loading-email-" + token).addClass("hide");
                if (data.success) {
                    $("#email-" + token).val("")
                    $("#enviar-folder-" + token).foundation('close');
                    new Noty({
                        type: "success",
                        timeout: 3000,
                        text: "Documentos enviado por email correctamente",
                    }).show();
                } else {

                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: data.message,
                    }).show();
                }
            },
            error: function (e) {
                $(".form-email-" + token).removeClass("hide");
                $(".loading-email-" + token).addClass("hide");
                new Noty({
                    type: "warning",
                    timeout: 3000,
                    text: "No se pudo enviar por email, intente mas tarde",
                }).show();

            }

        })

    });
    $(".enviar-folder-email-entidades").on("click", function () {
        var _this = $(this);
        var token = _this.data("token");
        $(".to_hide").addClass("hide");
        $(".loading-email-" + token).removeClass("hide");
        $.ajax({
            url: "/enviar_carpeta_email",
            type: 'POST',
            data: {token: token},
            success: function (data) {
                $(".to_hide").removeClass("hide");
                $(".loading-email-" + token).addClass("hide");
                if (data.success) {
                    $("#enviar-folder-opt-" + token).foundation('close');
                    new Noty({
                        type: "success",
                        timeout: 3000,
                        text: data.message,
                    }).show();

                } else {

                    new Noty({
                        type: "warning",
                        timeout: 3000,
                        text: data.message,
                    }).show();
                    if (data.warnings) {
                        var warning = "";
                        $.each(data.warning_details, function (i, row) {
                            warning += `<span class="red">${row} <span class="fa fa-times"></span></span><br>`;
                        })
                        var enviado = ""
                        if (data.enviados) {
                            $.each(data.enviados, function (i, row) {
                                enviado += `<span class="green">${row} <span class="fa fa-check"></span></span><br>`;
                            })
                            $("#enviados_emails_" + token).html(enviado)
                        } else {
                            $("#enviados_emails_" + token).html("<h4 class='red text-center'>No se envió ningun documento</h4>")
                        }
                        $("#advertencias_emails_" + token).html(warning)
                        $("#enviar-folder-warnings-" + token).foundation('open')
                    }
                }
            },
            error: function (e) {
                $(".to_hide").removeClass("hide");
                $(".loading-email-" + token).addClass("hide");
                new Noty({
                    type: "warning",
                    timeout: 3000,
                    text: "No se pudo enviar por email, intente mas tarde",
                }).show();

            }

        })

    });
}
