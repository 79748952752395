// CARGAR EL PDF EN CANVAS
window.cargarpdf = function (url, target, signing, tipo_pagina, pagina, funcLoaded) {



    var token = target.replace("canvas_pdf_custom_", "");

    $("#sign-" + token).find(".imgCapture").removeClass("hide");
    $("#sign-" + token).find(".imgFirma").addClass("hide");
    $("#sign-" + token).find(".limpiarFirma").addClass("hide")
    var load = document.getElementById("load_" + target.toString())
    load.style.display = "block"
    var canvas = document.getElementById(target);
    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    canvas.style.display = "none"
    //
    // The workerSrc property shall be specified.
    //

    // Asynchronous download PDF
    //
    try {
        var loadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise.then(function (pdf) {
            //
            // Fetch the first page
            //
            var page_preview = 1;

            if (tipo_pagina) {
                tipo_pagina = parseInt(tipo_pagina)
                if (tipo_pagina == 2)
                    page_preview = parseInt(pagina);
                else if (tipo_pagina == 1)
                    page_preview = 1;
                else if (tipo_pagina == -1)
                    page_preview = pdf.numPages;

            }
            if (funcLoaded)
                funcLoaded(pdf.numPages)
            pdf.getPage(page_preview).then(function (page) {

                var scale = 1;

                var viewport = page.getViewport({scale:scale});
                //
                // Prepare canvas using PDF page dimensions

                load.style.display = "none"
                canvas.style.display = "block"
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                $("#sign-" + token).find(".limpiarFirma").removeClass("hide")


                //
                // Render PDF page into canvas context
                //
                var renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };
                page.render(renderContext).promise.then(function () {
                    setTimeout(function(){
                        html2canvas($("#sign-" + token).find(".imgCapture")[0],{backgroundColor:null}).then(function(canvas) {
                            $("#sign-" + token).find(".imgCapture").addClass("hide");
                            $("#sign-" + token).find(".imgFirma").removeClass("hide");
                            var dataURL = canvas.toDataURL('image/png');
                            if(dataURL != "data:,")
                                $("#sign-" + token).find(".imgFirma").attr("src",dataURL);

                        })
                    },500)
                    if (signing) {
                        var data = canvas.toDataURL();
                            console.log("Canvas Render")
                            Canvas(data, target, "")


                    }
                });

            });

        });
    } catch (e) {
        canvas.style.display = "none";
        console.log(e)
        new Noty({
            type: "warning",
            timeout: 3000,
            text: "PDF Corrupto",
        }).show();
    }


}

// OBTIENE EL CONTEO DE PAGINAS DEL PDF
window.get_count_pages_pdf = function (url, funcLoaded) {
    var loadingTask = pdfjsLib.getDocument(url);
    loadingTask.promise.then(function (pdf) {
        var count = pdf.numPages;
        pdf.getPage(1).then(function (page) {
            var viewport = page.getViewport({scale:1});
            funcLoaded(count, viewport.width, viewport.height);
        });


    });


}

// RENDERIZA EL PDF EN EL CANVAS
window.Canvas = function (data, target, image) {
    var canvas = document.getElementById(target);
    var g = canvas.getContext('2d');
    var down = false;
    var token = target.replace("canvas_pdf_custom_", "");
    var $canvas = $("#" + target);
    var canvasOffset = $canvas.offset();
    var offsetX = canvasOffset.left;
    var offsetY = canvasOffset.top;
    var scrollX = $canvas.scrollLeft();
    var scrollY = $canvas.scrollTop();
    var startX;
    var startY;
    var selectedImage = null;
    var $tools = $("#sign-" + token).find(".imgFirma");

    // make all .tool's draggable
    $tools.draggable({
        helper: 'clone',
    });

    $("#sign-" + token).find(".limpiarFirma").on('click', function () {
        var pdfImg = new Image();
        pdfImg.src = data;
        g.clearRect(0, 0, canvas.width, canvas.height);
        setTimeout(function () {
            g.drawImage(pdfImg, 0, 0);
        }, 50);

        window.firma_desposicionada = true;
        $("#sign-" + token).find(".imgFirma").removeClass("hide");
    });


// assign each .tool its index in $tools
    $tools.each(function (index, element) {
        $(this).data("toolsIndex", index);
    });


// make the canvas a dropzone
    $canvas.droppable({
        drop: dragDrop,
    });


// handle a drop into the canvas
    function dragDrop(e, ui) {

        // get the drop point (be sure to adjust for border)
        var rect = canvas.getBoundingClientRect(),
            offsetX = rect.left,
            offsetY = rect.top;

        x = parseInt(ui.offset.left - offsetX) - 1;
        y = parseInt(ui.offset.top - offsetY);
        width = ui.helper[0].width;
        height = ui.helper[0].height;

        mouseX = parseInt(e.clientX - offsetX);
        mouseY = parseInt(e.clientY - offsetY);

        // drawImage at the drop point using the dropped image
        // This will make the img a permanent part of the canvas content


        var theIndex = ui.draggable.data("toolsIndex");
        image.url = $tools[theIndex].src;

        startX = mouseX;
        startY = mouseY;
        image.x = x;
        image.y = y;
        // drawImage at the drop point using the dropped image
        // This will make the img a permanent part of the canvas content
        $tools[theIndex].width = 232;
        $tools[theIndex].height = 50;
        g.drawImage($tools[theIndex], x, y, 232, 50);
        $("#sign_x_" + token).val(Math.floor(image.x))
        $("#sign_y_" + token).val(Math.floor(canvas.height - image.y - 50))
        $("#sign-" + token).find(".imgFirma").addClass("hide");
        window.firma_desposicionada = false;
        if (image.x < 1 || image.y < 1 || image.x + 232 > canvas.width - 1 || image.y > canvas.height - 1 - 50) {
            g.fillStyle = 'rgba(255, 0, 0, 0.3)'; // 30% opacity red
            g.fillRect(image.x, image.y, 232, 50); // Draw this over top of your image
            window.firma_desposicionada = true;
        } else {
            window.firma_desposicionada = false;
        }
    }


    var image = {
        url: "",
        x: 0,
        y: 0
    };

    function drawCanvas() {
        var pdfImg = new Image();
        pdfImg.src = data;
        g.clearRect(0, 0, canvas.width, canvas.height);

        g.drawImage(pdfImg, 0, 0);
        var img = new Image();
        img.src = image.url;
        img.width = 232;
        img.height = 50;
        g.drawImage(img, image.x, image.y, 232, 50);
        window.firma_desposicionada = false;
        if (image.x < 1 || image.y < 1 || image.x + 232 > canvas.width - 1 || image.y > canvas.height - 1 - 50) {
            g.fillStyle = 'rgba(255, 0, 0, 0.3)'; // 30% opacity red
            g.fillRect(image.x, image.y, 232, 50); // Draw this over top of your image
            window.firma_desposicionada = true;
        } else {
            window.firma_desposicionada = false;
        }
    }

    function imageHit(x, y) {
        return (x >= image.x && x <= image.x + 232 && y >= image.y && y - 50 <= image.y);
    }

    function handleMouseDown(e) {
        e.preventDefault();

        var rect = canvas.getBoundingClientRect(),
            offsetX = rect.left,
            offsetY = rect.top;
        mouseX = parseInt(e.clientX - offsetX);
        mouseY = parseInt(e.clientY - offsetY);
        startX = mouseX;
        startY = mouseY;
        if (imageHit(mouseX, mouseY)) {
            canvas.style.cursor = "move";
        } else {
            canvas.style.cursor = "default"
        }
        // Put your mousedown stuff here

        if (imageHit(mouseX, mouseY)) {
            selectedImage = image;
        }

    }

// done dragging
    function handleMouseUp(e) {
        e.preventDefault();
        selectedImage = null;
    }

// also done dragging
    function handleMouseOut(e) {
        e.preventDefault();
        selectedImage = null;
    }

    function handleMouseMove(e) {
        var rect = canvas.getBoundingClientRect(),
            offsetX = rect.left,
            offsetY = rect.top;
        mouseX = parseInt(e.clientX - offsetX);
        mouseY = parseInt(e.clientY - offsetY);
        if (imageHit(mouseX, mouseY)) {
            canvas.style.cursor = "move";


        } else {
            canvas.style.cursor = "default"
        }

        if (selectedImage == null) {
            return;
        }

        e.preventDefault();


        // Put your mousemove stuff here
        var dx = mouseX - startX;
        var dy = mouseY - startY;
        startX = mouseX;
        startY = mouseY;

        var image = selectedImage;
        image.x += dx;
        image.y += dy;
        $("#sign_x_" + token).val(Math.floor(image.x))
        $("#sign_y_" + token).val(Math.floor(canvas.height - image.y - 50))
        drawCanvas();
    }

    canvas.addEventListener('mousemove', function (e) {
        handleMouseMove(e);
    }, false);
    canvas.addEventListener('mousedown', function (e) {
        handleMouseDown(e)
    }, false);
    canvas.addEventListener('mouseup', function (e) {
        handleMouseUp(e)
    }, false);
    canvas.addEventListener('mouseout', function (e) {
        handleMouseOut(e)
    }, false);

}
//
// window.render_all_pdf = function(url, token) {
//
//     var frame = $("#preview-document-" + token);
//     frame.attr("src", url);
//     $("#content-document-" + token).show();
//
// }
//


window.render_all_pdf = function (url, token) {
    if (navigator.userAgent.search("Firefox") > -1) {
        var thePdf = null;
        var scale = 1;

        $(".loading-canvas-" + token).show();
        var frame = $("#preview-document-" + token);
        frame.attr("src", url);
        $("#content-document-" + token).show();

        var loadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise.then(function (pdf) {

            thePdf = pdf;
            var viewer = document.getElementById("preview-document-" + token);
            $("#cant-paginas-document-" + token).text(pdf.numPages + " páginas")
            for (var page = 1; page <= pdf.numPages; page++) {
                var canvas = document.createElement("canvas");
                canvas.className = 'pdf-page-canvas';
                viewer.appendChild(canvas);
                renderPage(page, canvas);
            }
            $(".loading-canvas-" + token).hide();


        });

        function renderPage(pageNumber, canvas) {
            thePdf.getPage(pageNumber).then(function (page) {
                var viewport = page.getViewport({scale:scale});
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                page.render({canvasContext: canvas.getContext('2d'), viewport: viewport});
            });
        }

    } else {
        var frame = $("#preview-document-iframe-" + token);
        frame.attr("src", url);
        $("#content-document-" + token).show();
    }

}

